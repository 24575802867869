/* eslint-disable import/no-duplicates */
import { Duration } from 'date-fns'
import add from 'date-fns/add'
import addDays from 'date-fns/addDays'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'
import addMonths from 'date-fns/addMonths'
import compareAsc from 'date-fns/compareAsc'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import endOfDay from 'date-fns/endOfDay'
import endOfYear from 'date-fns/endOfYear'
import format from 'date-fns/format'
import getDate from 'date-fns/getDate'
import getMonth from 'date-fns/getMonth'
import getWeek from 'date-fns/getWeek'
import getYear from 'date-fns/getYear'
import intervalToDuration from 'date-fns/intervalToDuration'
import enUS from 'date-fns/locale/en-US'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import set from 'date-fns/set'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import startOfYear from 'date-fns/startOfYear'
import subMonths from 'date-fns/subMonths'

export const DateMapping = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}

export const ShortMonthMapping = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
}

export const isValidDate = (date) => {
  if (date?.toString() === 'Invalid Date') {
    return false
  }
  if (!date) return false
  return true
}

export const Timezone = {
  singapore: 'Asia/Singapore'
}

export const dateFormat = 'yyyy-MM-dd'
export const timeFormat = 'HH:mm:ss'

export const FIRST_DAY_OF_WEEK = 1

export function utcStringToUserTimezone(
  date: string,
  timezone: string = Timezone.singapore
) {
  //REFACTOR: remove add hour after backend add timezone into timestamp
  const setDate = addHours(new Date(date), 7).toLocaleString('en-US', {
    timeZone: timezone
  })

  const splitDate = setDate.split(',')
  const [month, day, year] = splitDate[0].split('/')
  const reformatDate = formatDefaultDate(`${year}-${month}-${day}`)

  return `${reformatDate}, ${splitDate[1]}`
}

export function parseDate(
  dateString: string,
  referenceDate: Date | number = parseDate.date,
  format: string = dateFormat
): Date {
  if (parseDate.cache[dateString]) {
    return parseDate.cache[dateString]
  }

  parseDate.cache[dateString] = parse(dateString, format, referenceDate)
  return parseDate.cache[dateString]
}
parseDate.cache = {} as { [key: string]: Date }
parseDate.date = new Date()

export function formatDate(
  date: Date | number,
  template: string = dateFormat
): string {
  if (!date || date.toString() === 'Invalid Date') {
    return 'Date is invalid'
  }

  return format(date, template)
}

export function formatFromISOString(
  date: string,
  template: string = dateFormat,
  shortMonth?: string
): string {
  try {
    const isoDate = parseISO(date)
    if (shortMonth === 'short') {
      // For AM PM time formate and short month
      return `${formatDefaultDate(format(isoDate, dateFormat))}, ${format(
        isoDate,
        template
      )}`
    }

    return format(isoDate, template)
  } catch (e) {
    console.log(`can not parse date ISO string: ${date}`)
    return date
  }
}

export function parseDateTime(
  dateString,
  timeString,
  referenceDate: Date | number = new Date()
): Date {
  const dateTimeString = `${dateString}-${timeString}`
  if (parseDateTime.cache[dateTimeString]) {
    return parseDateTime.cache[dateTimeString]
  }
  parseDateTime.cache[dateTimeString] = parse(
    dateTimeString,
    'yyyy-MM-dd-HH:mm:ss',
    referenceDate
  )
  return parseDateTime.cache[dateTimeString]
}
parseDateTime.cache = {} as { [key: string]: Date }

export function formatDateTime(
  date: Date | number,
  template: string = 'yyyy-MM-dd HH:mm:ss'
): string {
  if (!date || date.toString() === 'Invalid Date') {
    return 'Date is invalid'
  }

  return format(date, template)
}

export function addTime(timeString, date: Date): Date {
  const [hours, minutes] = timeString.split(':')
  let newDate = addHours(date, hours)
  newDate = addMinutes(newDate, minutes)
  return newDate
}

export function getMonthStartDate(
  date: Date,
  template: string = dateFormat
): string {
  if (!date || date.toString() === 'Invalid Date') {
    return 'Date is invalid'
  }

  const startOfMonthDate = startOfMonth(date)
  return format(startOfMonthDate, template)
}

export function getYearStartDate(
  date: Date,
  template: string = dateFormat
): string {
  const startOfYearDate = startOfYear(date)
  return format(startOfYearDate, template)
}

export function getYearEndDate(
  date: Date,
  template: string = 'yyyy-MM-dd'
): string {
  if (!date || date.toString() === 'Invalid Date') {
    return 'Date is invalid'
  }

  const endOfYearDate = endOfYear(date)
  return format(endOfYearDate, template)
}

export function getDateString(dateString: string): {
  monthString: string
  yearString: string
  monthYearString: string
  shortMonthString: string
} {
  const date = parseDate(dateString)
  const monthInt = getMonth(date)
  const yearInt = getYear(date)

  return {
    monthString: DateMapping[monthInt],
    shortMonthString: ShortMonthMapping[monthInt],
    yearString: yearInt.toString(),
    monthYearString: `${ShortMonthMapping[monthInt]} ${yearInt.toString()}`
  }
}

export function getMonthString(dateString: string): string {
  const date = parseDate(dateString)
  return DateMapping[getMonth(date)]
}

export function getDateFormatPayslip(dateString: string): string {
  const date = parseDate(dateString)
  const monthInt = getMonth(date) + 1
  const yearInt = getYear(date)
  const dateInt = getDate(date)

  return `${dateInt}/${monthInt}/${yearInt}`
}

export function utcDatetoDateString(utcDate: string): string {
  const date = new Date(utcDate)
  const monthInt = getMonth(date)
  const yearInt = getYear(date)
  const dateInt = getDate(date)

  return `${DateMapping[monthInt]} ${dateInt}, ${yearInt}`
}

export function setDateTime(
  inputDate: Date | string,
  inputTime: Date | string
): Date {
  let parsedInputDate: Date
  let parsedInputTime: Date

  if (typeof inputDate == 'string') {
    parsedInputDate = new Date(inputDate)
  } else {
    parsedInputDate = inputDate
  }

  if (typeof inputTime == 'string') {
    parsedInputTime = new Date(inputTime)
  } else {
    parsedInputTime = inputTime
  }

  const dateTime = set(new Date(), {
    year: parsedInputDate.getFullYear(),
    month: parsedInputDate.getMonth(),
    date: parsedInputDate.getDate(),
    hours: parsedInputTime.getHours(),
    minutes: parsedInputTime.getMinutes(),
    seconds: parsedInputTime.getSeconds()
  })

  return dateTime
}

export function yupDateStringTransform(value, originalValue): Date {
  if (!originalValue) {
    return originalValue
  }
  return parseDate(originalValue)
}

export function yupTimeStringToMinutes(time: string): number {
  const [h, m] = time.split(':')
  return parseInt(h, 10) * 60 + parseInt(m, 10)
}

export function formatInvoiceMonth(date: string): string {
  if (!date) {
    return ''
  }
  try {
    return format(parseISO(date), 'LLL yyyy', { locale: enUS })
  } catch (e) {
    console.log(`can not parse date: ${date}`)
    return date
  }
}

export function getXeroStartEndDate(date: Date = new Date()): {
  startDate: string
  endDate: string
} {
  const endDate = add(date, { months: 3 })
  return {
    startDate: formatDate(date),
    endDate: formatDate(endDate)
  }
}

export function getDateFromString(date: string): number {
  return new Date().setTime(Date.parse(date))
}

export const getMonthYearValue = (date: string) => {
  if (!date) {
    return ''
  }
  const setValueDate = date.split('-')
  return `${setValueDate[0]}-${setValueDate[1]}`
}

export function getMonthYear(date: string): {
  monthInt: number
  yearInt: number
} {
  const setDateValue = date.split('-')
  const monthInt = parseInt(setDateValue[1])
  const yearInt = parseInt(setDateValue[0])

  return {
    monthInt,
    yearInt
  }
}

export const getFirstDayOfTheMonth = (date: string) => {
  if (!date) {
    return ''
  }
  const { monthInt, yearInt } = getMonthYear(date)
  const firstDayOfMonth = new Date(yearInt, monthInt - 1, 1)
  return formatDate(firstDayOfMonth)
}

export const getLastDayOfTheMonth = (date: string) => {
  if (!date) {
    return ''
  }
  const { monthInt, yearInt } = getMonthYear(date)
  const lastDayOfMonth = new Date(yearInt, monthInt, 0)

  return formatDate(lastDayOfMonth)
}

export const getDateStringWithoutZone = (date: Date): string => {
  const key = date.toString()
  if (getDateStringWithoutZone.cache[key]) {
    return getDateStringWithoutZone.cache[key]
  }

  getDateStringWithoutZone.cache[key] = format(date, "yyyy-MM-dd'T'HH:mm:ss")
  return getDateStringWithoutZone.cache[key]
}
getDateStringWithoutZone.cache = {} as { [key: string]: string }

export const getMaxDateString = (dates: string[]): string => {
  const parsedDates = dates.map((date) => parseDate(date).valueOf())
  const maxDate = Math.max(...parsedDates)
  return formatDate(maxDate)
}

export const getFirstDayPreviousMonth = (date: Date): string => {
  if (!date || date.toString() === 'Invalid Date') {
    return 'Date is invalid'
  }

  const previousMonth = subMonths(date, 1)
  return getFirstDayOfTheMonth(formatDate(previousMonth))
}

export const getLastDayNextMonth = (date: Date): string => {
  if (!date || date.toString() === 'Invalid Date') {
    return 'Date is invalid'
  }

  const nextMonth = addMonths(date, 1)
  return getFirstDayOfTheMonth(formatDate(nextMonth))
}

export function parseTimeString(
  timeString: string,
  referenceDate: Date | number = parseDate.date,
  format: string = timeFormat
): Date {
  if (parseTimeString.cache[timeString]) {
    return parseTimeString.cache[timeString]
  }
  parseTimeString.cache[timeString] = parse(timeString, format, referenceDate)
  return parseTimeString.cache[timeString]
}
parseTimeString.cache = {} as { [key: string]: Date }

export function getDurationTime(
  startTime: string | null,
  endTime: string | null
): Duration | undefined {
  if (!startTime || !endTime) {
    return
  }
  const start = parseTimeString(startTime)
  const end = parseTimeString(endTime)

  return intervalToDuration({ start, end })
}

export function formatDurationFromMinutes(minutes: number): string {
  if (minutes < 60) return `${minutes}m`

  const hour = Math.floor(minutes / 60)
  const minute = minutes % 60

  return `${hour}h ${minute}m`
}

export function splitTimeInt(timeString: string): {
  hour: number
  minute: number
  second: number
} {
  const [hour, minute, second] = timeString.split(':').map((v) => Number(v))
  return {
    hour,
    minute,
    second
  }
}

type FormatTimeOption = {
  separator?: string
  ignoreZero?: boolean
  capitalize?: 'lowercase' | 'uppercase'
}

// 09:50:31

// 09:50

export function formatTime24h(
  timeString: string,
  options: FormatTimeOption = {
    separator: ':',
    ignoreZero: false,
    capitalize: 'uppercase'
  }
): string {
  const { separator, ignoreZero, capitalize } = options
  const keyname = `${timeString}-${options}-${ignoreZero}-${capitalize}`

  if (!timeString) {
    return 'No Time'
  }

  if (formatTime24h.cache[keyname]) {
    return formatTime24h.cache[keyname]
  }

  const hours =
    splitTimeInt(timeString).hour < 10
      ? `${ignoreZero ? '' : '0'}${splitTimeInt(timeString).hour}`
      : splitTimeInt(timeString).hour
  const minutes =
    splitTimeInt(timeString).minute < 10
      ? `${ignoreZero ? '' : '0'}${splitTimeInt(timeString).minute}`
      : splitTimeInt(timeString).minute

  formatTime24h.cache[keyname] = `${hours}${separator}${minutes}`

  return formatTime24h.cache[keyname]
}
formatTime24h.cache = {} as { [key: string]: string }

export function formatDefaultDate(date: string): string {
  if (!date) {
    return 'No Date'
  }

  const setDateValue = date.split('-')
  const dayInt = parseInt(setDateValue[2])
  const monthInt = parseInt(setDateValue[1])
  const yearInt = parseInt(setDateValue[0])
  const leadingZeroDay = dayInt < 10 ? `0${dayInt}` : dayInt
  const leadingZeroMonth = monthInt < 10 ? `0${monthInt}` : monthInt

  return `${leadingZeroDay}/${leadingZeroMonth}/${yearInt}`
}

export function isDateValid(date: Date): string | undefined {
  if (!date || date.toString() === 'Invalid Date') {
    return
  }

  return formatDate(date)
}

export function getFirstLastDateinWeek(
  date: Date,
  firstDayOfWeek = FIRST_DAY_OF_WEEK
): [Date, Date] {
  if (!(firstDayOfWeek >= 0 && firstDayOfWeek <= 6)) {
    throw new RangeError('firstDayOfWeek must be between 0 and 6 inclusively')
  }

  if (getFirstLastDateinWeek.cache[date.toDateString()]) {
    return getFirstLastDateinWeek.cache[date.toDateString()]
  }

  const day = date.getDay()
  const diff = (day < firstDayOfWeek ? 7 : 0) + day - firstDayOfWeek

  const firstDay = addDays(date, -diff)
  const lastDay = addDays(firstDay, 6)

  getFirstLastDateinWeek.cache[date.toDateString()] = [firstDay, lastDay]

  return getFirstLastDateinWeek.cache[date.toDateString()]
}
getFirstLastDateinWeek.cache = {} as { [key: string]: [Date, Date] }

export function getWeekFromDateString(
  dateString: string,
  shouldStartOfWeek = false
): number {
  const cacheName = `${dateString}-${shouldStartOfWeek}`
  if (getWeekFromDateString.cache[cacheName]) {
    return getWeekFromDateString.cache[cacheName]
  }

  let date = parseDate(dateString)
  if (shouldStartOfWeek) {
    date = startOfWeek(date, {
      weekStartsOn: FIRST_DAY_OF_WEEK
    })
  }

  getWeekFromDateString.cache[cacheName] = getWeek(date)

  return getWeekFromDateString.cache[cacheName]
}
getWeekFromDateString.cache = {} as { [key: string]: number }

export function getDateRangeBetween(startDate: Date, endDate: Date): Date[] {
  const dates: Date[] = []
  let currentDate = startOfDay(startDate)
  while (currentDate <= endOfDay(endDate)) {
    dates.push(currentDate)
    currentDate = addDays(currentDate, 1)
  }
  return dates
}

export const compareTime = (time: string, timeDefault: string) => {
  const startTime = getDateFromString(`1970-01-01T${time}:00`)
  const defaultTime = getDateFromString(`1970-01-01T${timeDefault}:00`)

  return compareAsc(defaultTime, startTime)
}

export const weekDateToDate = (
  week: number,
  day: number,
  year: number = new Date().getFullYear()
): Date => {
  const firstDayOfYear = new Date(year, 0, 1)
  const days = 2 + day + (week - 1) * 7 - firstDayOfYear.getDay()
  return new Date(year, 0, days)
}

export const getDay = (date: Date): number => {
  const jsDay = date.getDay() - 1
  if (jsDay < 0) {
    return 6
  } else {
    return jsDay
  }
}

export const getDayNamePrefix = (dayNumber: number): string => {
  const currentDate = new Date()

  const referenceDate = addDays(
    currentDate,
    -currentDate.getDay() + dayNumber + 1
  )
  const dayName = format(referenceDate, 'EEE')
  return dayName
}

export const removeSeconds = (time: string): string => {
  return time.replace(/:[^:]*$/, '')
}

export function getMinuteFromTimeInterval(
  startTime: string,
  endTime: string
): number {
  const keyname = `${startTime}-${endTime}`

  if (getMinuteFromTimeInterval.cache[keyname]) {
    return getMinuteFromTimeInterval.cache[keyname]
  }

  const startDate = new Date(`1970-01-01T${startTime}`)
  const endDate = new Date(`1970-01-01T${endTime}`)
  const hour = differenceInMinutes(endDate, startDate)

  getMinuteFromTimeInterval.cache[keyname] = hour
  return getMinuteFromTimeInterval.cache[keyname]
}
getMinuteFromTimeInterval.cache = {} as { [key: string]: number }

export function addHoursTimeString(timeString: string, hours: number): string {
  timeString = timeString.padEnd(8, ':00')
  const keyname = `${timeString}-${hours}`

  if (addHoursTimeString.cache[keyname]) {
    return addHoursTimeString.cache[keyname]
  }

  const hoursInMinutes = hours * 60

  const timeInDate = parseTimeString(timeString)
  const newTime = addMinutes(timeInDate, hoursInMinutes)

  addHoursTimeString.cache[keyname] = formatDate(newTime, timeFormat)
  return addHoursTimeString.cache[keyname]
}
addHoursTimeString.cache = {} as { [key: string]: string }

export const isDateRangeValid = (
  startDate: string | Date,
  endDate: string | Date,
  maxDays: number = 60
): boolean => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    console.error('Invalid date format')
    return false
  }

  const timeDifference = end.getTime() - start.getTime()
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24)

  return dayDifference <= maxDays
}

export const formatToSGT = (utcDate: string | null | undefined): string => {
  if (!utcDate) return 'N/A'

  return new Date(utcDate).toLocaleString('en-SG', {
    timeZone: 'Asia/Singapore',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  })
}