import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  PackageDepartmentEnum,
  PackageServiceTypeEnum,
  PackageUnitTypeEnum
} from '__generated__/globalTypes'
import { setPackageDataAction } from 'components/package/package.slice'
import isEqual from 'lodash.isequal'
import { PackageDetail } from 'ts/types/Package'

import { RootState } from '../../redux/slices'
import {
  CARPET_UPHOLSTERY_CODES,
  DISCOUNT_UPHOLSTERY_CODE,
  getUnitLabel,
  isUnitFormDisable,
  LineItem,
  transformPackageLineItems
} from './lib'

type AddLineItem = {
  lineItem: LineItem
}

type UpdateLineItem = {
  id: string
  lineItem: LineItem
}
type RemoveLineItem = {
  id: string
}

type SetLineItems = {
  lineItems: LineItem[]
}

type SetEditLineItemModal = {
  lineItem: LineItem
  type: LineItemType
}

export type AppliedPromo = {
  appliedPromoCode: string
  computedDiscount: number
}

export type LineItemType =
  | 'package'
  | 'visit'
  | 'task'
  | 'firstVisit'
  | 'scheduler'

type State = {
  lineItems: LineItem[]
  previousLineItems: LineItem[]
  firstVisitLineItems: LineItem[]
  currentLineItem: LineItem | null
  modal: boolean
  lineItemType: LineItemType | null
  shouldRunEffect: boolean
  promo: AppliedPromo
}

const initialState: State = {
  lineItems: [],
  previousLineItems: [],
  firstVisitLineItems: [],
  currentLineItem: null,
  modal: false,
  lineItemType: null,
  shouldRunEffect: false,
  promo: {
    appliedPromoCode: '',
    computedDiscount: 0
  }
}

const lineItemSlice = createSlice({
  name: 'lineItem',
  initialState,
  reducers: {
    setLineItems(state, action: PayloadAction<SetLineItems>) {
      state.lineItems = action.payload.lineItems
      state.previousLineItems = action.payload.lineItems
    },
    setFirstVisitLineItems(state, action: PayloadAction<SetLineItems>) {
      state.firstVisitLineItems = action.payload.lineItems
    },
    resetLineItemsToPrevious(state) {
      state.lineItems = state.previousLineItems
    },
    addLineItem(state, { payload }: PayloadAction<AddLineItem>) {
      if (state.lineItemType === 'firstVisit') {
        state.firstVisitLineItems.push(payload.lineItem)
      } else {
        state.lineItems.push(payload.lineItem)
      }
    },
    updateLineItem(state, { payload }: PayloadAction<UpdateLineItem>) {
      if (state.lineItemType === 'firstVisit') {
        state.firstVisitLineItems = state.firstVisitLineItems.map((item) => {
          if (item.id === payload.id) {
            item = payload.lineItem
          }

          return item
        })
      } else {
        state.lineItems = state.lineItems.map((item) => {
          if (item.id === payload.id) {
            item = payload.lineItem
          }

          return item
        })
      }
    },
    removeLineItem(state, { payload }: PayloadAction<RemoveLineItem>) {
      if (state.lineItemType === 'firstVisit') {
        state.firstVisitLineItems = state.firstVisitLineItems.filter(
          ({ id }) => id !== payload.id
        )
      } else {
        state.lineItems = state.lineItems.filter(({ id }) => id !== payload.id)
        if (state.lineItems.length === 0) {
          state.promo = {
            appliedPromoCode: '',
            computedDiscount: 0
          }
        }
      }
    },
    setDiscountLineItemUpholstery(
      state,
      {
        payload
      }: PayloadAction<{ packageDetail: PackageDetail; discountNumber: number }>
    ) {
      const { packageDetail, discountNumber } = payload

      // Filter discount line item from database
      state.lineItems = state.lineItems.filter(
        (lineItem) => lineItem.packageCode !== DISCOUNT_UPHOLSTERY_CODE
      )

      const totalBillingValue = state.lineItems.reduce<number>(
        (total, lineItem) => {
          if (lineItem.packageDetail.unitType === PackageUnitTypeEnum.CARPET) {
            return total
          }

          return total + lineItem.totalValue
        },
        0
      )

      let valueDiscount: number

      switch (discountNumber) {
        case 5:
          valueDiscount = 0.05
          break
        case 10:
          valueDiscount = 0.1
          break
        case 15:
          valueDiscount = 0.15
          break
        default:
          valueDiscount = 0
          break
      }

      const discountCalculation = totalBillingValue * valueDiscount

      const discountLineItem = {
        id: packageDetail.code,
        packageCode: packageDetail.code,
        description: `${packageDetail.description} ${discountNumber}%`,
        duration: packageDetail.duration,
        unitsNumber: packageDetail.units,
        unitValue: discountCalculation,
        unitsLabel: getUnitLabel(
          packageDetail.billingUnit,
          packageDetail.units,
          packageDetail.code
        ),
        totalValue: discountCalculation,
        packageDetail
      }

      state.lineItems.push(discountLineItem)
    },
    clearAllLineItems(state): void {
      state.lineItems = []
      state.firstVisitLineItems = []
    },
    showAddLineItemModal(
      state,
      { payload }: PayloadAction<{ type: LineItemType }>
    ) {
      state.modal = true
      state.lineItemType = payload.type
      state.currentLineItem = null
    },
    hideLineItemModal(state) {
      state.modal = false
      state.lineItemType = null
      state.currentLineItem = null
    },
    showDetailLineItemModal(
      state,
      action: PayloadAction<SetEditLineItemModal>
    ) {
      state.modal = true
      state.currentLineItem = action.payload.lineItem
      state.lineItemType = action.payload.type
    },
    setShouldRunEffect(
      state,
      action: PayloadAction<{ shouldRunEffect: boolean }>
    ) {
      state.shouldRunEffect = action.payload.shouldRunEffect
    },
    setAppliedPromo(state, action: PayloadAction<AppliedPromo>) {
      const { appliedPromoCode, computedDiscount } = action.payload

      state.promo = {
        appliedPromoCode,
        computedDiscount
      }
    },
    clearAppliedPromo(state): void {
      state.promo = {
        appliedPromoCode: '',
        computedDiscount: 0
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setPackageDataAction, (state, { payload }) => {
      state.lineItems = transformPackageLineItems(
        payload.packageData.packageLineItems!
      )
    })
  }
})

export const lineItemTypeSelector = (state: RootState) =>
  state.lineItemReducer.lineItemType

export const lineItemsSelector = (state: RootState) =>
  state.lineItemReducer.lineItems

export const firstVisitlineItemsSelector = (state: RootState) =>
  state.lineItemReducer.firstVisitLineItems

export const addLineItemModalSelector = (state: RootState) =>
  state.lineItemReducer.modal

export const currentLineItemSelector = (state: RootState) =>
  state.lineItemReducer.currentLineItem

export const shouldRunEffectSelector = (state: RootState): boolean =>
  state.lineItemReducer.shouldRunEffect

export const promoSelector = (state: RootState) => state.lineItemReducer.promo

export const isUnchanged = (state: RootState) =>
  isEqual(
    state.lineItemReducer.lineItems,
    state.lineItemReducer.previousLineItems
  )

export const lineItemPackageIdsSelector = createSelector(
  (state: RootState) => state.lineItemReducer.lineItems,
  (lineItems) => lineItems.map((item) => item.packageDetail.id)
)

export const firstVisitLineItemPackageIdsSelector = createSelector(
  (state: RootState) => state.lineItemReducer.firstVisitLineItems,
  (firstVisitLineItems) =>
    firstVisitLineItems.map((item) => item.packageDetail.id)
)

export const existingLineItemIdsSelector = createSelector(
  (state: RootState) => state.lineItemReducer.lineItemType === 'firstVisit',
  firstVisitlineItemsSelector,
  lineItemsSelector,
  (isFirstVisit, firstVisitLineItems, lineItems) =>
    (isFirstVisit ? firstVisitLineItems : lineItems).map(({ id }) => id)
)

export const upholsteryTotalUnitSelector = createSelector(
  (state: RootState) => state.lineItemReducer.lineItems,
  (lineItems) =>
    lineItems.reduce<number>((total, lineItem) => {
      const { serviceType, unitType } = lineItem.packageDetail

      if (
        serviceType !== PackageServiceTypeEnum.DISCOUNT &&
        serviceType !== PackageServiceTypeEnum.ADJUSTMENT &&
        unitType !== PackageUnitTypeEnum.CARPET
      ) {
        return total + lineItem.unitsNumber
      }

      return total
    }, 0)
)

export const grandTotalPackageDetailSelector = (isFirstVisit?: boolean) =>
  createSelector(
    firstVisitlineItemsSelector,
    lineItemsSelector,
    (firstVisitLineItems, lineItems) => {
      let items: LineItem[]
      if (isFirstVisit === true) {
        items = firstVisitLineItems
      } else if (isFirstVisit === false) {
        items = lineItems
      } else {
        items = [...firstVisitLineItems, ...lineItems]
      }

      return items.reduce<number>((total, item) => {
        if (item.id === DISCOUNT_UPHOLSTERY_CODE) {
          return total - item.totalValue
        }
        return total + item.totalValue
      }, 0)
    }
  )

export const totalDurationLineItemsSelector = createSelector(
  firstVisitlineItemsSelector,
  lineItemsSelector,
  (firstVisitLineItems, lineItems) => {
    const items = [...firstVisitLineItems, ...lineItems]

    return items.reduce<number>((total, item) => {
      if (CARPET_UPHOLSTERY_CODES.includes(item.packageDetail.code)) {
        const carpetDuration = (item.unitsNumber / 100) * item.duration
        return total + carpetDuration
      }

      const isUnitFormDisabled = isUnitFormDisable(
        'add',
        'package',
        item.packageDetail
      )
      const withAllowedEditUnits =
        !isUnitFormDisabled.totalUnitDisabled ||
        !isUnitFormDisabled.unitValueDisabled

      const computeDurationByUnits = withAllowedEditUnits
        ? (item.unitsNumber / item.packageDetail.units) *
          item.packageDetail.duration
        : item.packageDetail.duration

      return total + computeDurationByUnits
    }, 0)
  }
)

export const canDeleteVisitLineItemSelector = createSelector(
  lineItemsSelector,
  currentLineItemSelector,
  (lineItems, currentLineItem) => {
    const regularLineItems = lineItems.filter(
      (item) => !item.packageDetail.serviceType.includes('ADJUSTMENT')
    )

    const department = currentLineItem?.packageDetail.department
    const isRegularServiceType = !['ADJUSTMENT', 'DISCOUNT'].includes(
      currentLineItem?.packageDetail.serviceType ?? ''
    )

    if (!isRegularServiceType) {
      return true
    }

    const hasSameCodePackageDetails = lineItems.some(
      (item) =>
        item.packageDetail.code === currentLineItem?.packageDetail.code &&
        item.id !== currentLineItem?.id
    )

    if (hasSameCodePackageDetails) {
      return true
    }

    const isSingleLineItemInDepartment =
      department &&
      [
        PackageDepartmentEnum.HOME_CLEANING,
        PackageDepartmentEnum.OFFICE_CLEANING
      ].includes(department) &&
      isRegularServiceType &&
      lineItems.length === regularLineItems.length

    if (isSingleLineItemInDepartment) {
      return false
    }

    return regularLineItems.length > 1
  }
)

export const lineItemSkillIdsSelector = createSelector(
  lineItemsSelector,
  firstVisitlineItemsSelector,
  (lineItems, firstVisitLineItems) => {
    const lineItemSkillIds = lineItems.map(
      (lineItem) => lineItem.packageDetail.workerSkill.id
    )
    const firstVisitLineItemIds = firstVisitLineItems.map(
      (lineItem) => lineItem.packageDetail.workerSkill.id
    )

    const skillIds = new Set([...lineItemSkillIds, ...firstVisitLineItemIds])

    return Array.from(skillIds)
  }
)

const { name, actions, reducer } = lineItemSlice
export { name, actions, reducer, actions as lineItemActions }
