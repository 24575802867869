/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuditableTypesEnum {
  CLIENT = "CLIENT",
  INVOICE = "INVOICE",
  JOB = "JOB",
  PACKAGE = "PACKAGE",
  PAYROLL = "PAYROLL",
  VISIT = "VISIT",
  WORKER = "WORKER",
  WORKER_TASK = "WORKER_TASK",
}

export enum BankAccountEnum {
  DBS = "DBS",
  STRIPE = "STRIPE",
}

export enum BankNameEnum {
  ANZ = "ANZ",
  BNPParibas = "BNPParibas",
  BangkokBank = "BangkokBank",
  BankofIndia = "BankofIndia",
  BankofSingapore = "BankofSingapore",
  CIMB = "CIMB",
  Citibank = "Citibank",
  DBSBank = "DBSBank",
  HSBC = "HSBC",
  HongLeongFinance = "HongLeongFinance",
  ICBCBank = "ICBCBank",
  ICICBank = "ICICBank",
  IndianOverseasBank = "IndianOverseasBank",
  IslamicBankofAsia = "IslamicBankofAsia",
  JPMorgan = "JPMorgan",
  Maybank = "Maybank",
  OCBC = "OCBC",
  RHBBank = "RHBBank",
  SBI = "SBI",
  StandardChartered = "StandardChartered",
  TrustBank = "TrustBank",
  UOB = "UOB",
}

export enum BillingTypeEnum {
  POSTPAID = "POSTPAID",
  PREPAID = "PREPAID",
}

export enum CancelVisitReasonEnum {
  CANCELLED_BY_CLIENT = "CANCELLED_BY_CLIENT",
  CANCELLED_BY_OPS = "CANCELLED_BY_OPS",
  CANCELLED_BY_WORKER = "CANCELLED_BY_WORKER",
  MISSED_BY_WORKER = "MISSED_BY_WORKER",
  UNABLE_TO_SCHEDULE = "UNABLE_TO_SCHEDULE",
}

export enum ChangeHistorySortByEnum {
  created_at = "created_at",
  id = "id",
  updated_at = "updated_at",
  version = "version",
}

export enum ChannelEnum {
  EMAIL = "EMAIL",
  PUSH_NOTIF = "PUSH_NOTIF",
  SMS = "SMS",
  WHATSAPP = "WHATSAPP",
}

export enum ClientAccountTypeEnum {
  CORPORATE = "CORPORATE",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum ClientConversationAuthorEnum {
  AGENT = "AGENT",
  CLIENT = "CLIENT",
  SYSTEM = "SYSTEM",
}

export enum ClientConversationChannelEnum {
  CALL = "CALL",
  EMAIL = "EMAIL",
  WHATSAPP = "WHATSAPP",
}

export enum ClientConversationSortByEnum {
  created_at = "created_at",
  date_created = "date_created",
  id = "id",
}

export enum ClientCrmStageEnum {
  ALL = "ALL",
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

export enum ClientCrmTypeEnum {
  DEAL = "DEAL",
  TICKET = "TICKET",
}

export enum ClientReferralSortByEnum {
  updated_at = "updated_at",
}

export enum ClientSortByEnum {
  account_type = "account_type",
  created_at = "created_at",
  name = "name",
  status = "status",
  updated_at = "updated_at",
}

export enum ClientStatusEnum {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
  RESTRICTED = "RESTRICTED",
}

export enum ClientStatusReferralEnum {
  PAID = "PAID",
  PENDING = "PENDING",
}

export enum ContractBillingTermsEnum {
  IMMEDIATE = "IMMEDIATE",
  NET_14 = "NET_14",
  NET_15 = "NET_15",
  NET_30 = "NET_30",
  NET_45 = "NET_45",
  NET_60 = "NET_60",
  NET_7 = "NET_7",
  NET_90 = "NET_90",
}

export enum ContractInvoiceFrequencyEnum {
  PER_MONTH = "PER_MONTH",
  PER_VISIT = "PER_VISIT",
}

export enum ContractPayrollUnitEnum {
  HOURLY = "HOURLY",
  MONTHLY = "MONTHLY",
}

export enum ContractStatusEnum {
  ACTIVE = "ACTIVE",
  TERMINATED = "TERMINATED",
}

export enum ContractTypeEnum {
  CASUAL = "CASUAL",
  CONTRACT = "CONTRACT",
  FULL_TIME = "FULL_TIME",
  HOURLY = "HOURLY",
}

export enum CreationReasonEnum {
  MIGRATION = "MIGRATION",
  NEW_SALE = "NEW_SALE",
  PACKAGE_UPDATE = "PACKAGE_UPDATE",
}

export enum CreditAccountSortByEnum {
  balance = "balance",
  created_at = "created_at",
  credit_type = "credit_type",
  expiration_date = "expiration_date",
  id = "id",
}

export enum CreditAccountStatusEnum {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
}

export enum CreditAccountTransactionStatusEnum {
  AUTHORIZED = "AUTHORIZED",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
  VOID = "VOID",
}

export enum CreditAccountTransactionTypeEnum {
  CASH_WITHDRAWAL = "CASH_WITHDRAWAL",
  CREDIT_ABSORPTION = "CREDIT_ABSORPTION",
  INITIAL_BALANCE = "INITIAL_BALANCE",
  OVERPAYMENT = "OVERPAYMENT",
  PAYMENT = "PAYMENT",
  REFERRAL = "REFERRAL",
  REFUND = "REFUND",
  REWARD = "REWARD",
  SERVICE_COMPENSATION = "SERVICE_COMPENSATION",
  TAX_WRITE_OFF = "TAX_WRITE_OFF",
  TOP_UP = "TOP_UP",
}

export enum CreditTypeEnum {
  GENERAL = "GENERAL",
  PACKAGE = "PACKAGE",
}

export enum DiscountTypeEnum {
  FIXED = "FIXED",
  PERCENT = "PERCENT",
}

export enum EmailStatusEnum {
  DELIVERED = "DELIVERED",
  IN_PROCESS = "IN_PROCESS",
  NOT_DELIVERED = "NOT_DELIVERED",
  WAITING_TO_BE_SENT = "WAITING_TO_BE_SENT",
}

export enum IncentiveTypesEnum {
  CREDIT = "CREDIT",
  PROMO_CODE = "PROMO_CODE",
  VOUCHER = "VOUCHER",
}

export enum InvoicePaymentStatusEnum {
  PAID = "PAID",
  STRIPE_PROCESSING = "STRIPE_PROCESSING",
  UNDERPAID = "UNDERPAID",
  UNPAID = "UNPAID",
}

export enum InvoiceSortByEnum {
  amount = "amount",
  base_amount = "base_amount",
  billing_account_client_name = "billing_account_client_name",
  contract_id = "contract_id",
  created_at = "created_at",
  due_date = "due_date",
  id = "id",
  issue_date = "issue_date",
  payment_status = "payment_status",
  updated_at = "updated_at",
}

export enum InvoiceStatusEnum {
  CANCELLED = "CANCELLED",
  CONFIRMED = "CONFIRMED",
  DELETED = "DELETED",
  NEW = "NEW",
}

export enum IssueTypeEnum {
  BOOKING_COMMENT = "BOOKING_COMMENT",
  CLEANER_REPLACEMENT = "CLEANER_REPLACEMENT",
  GENERAL = "GENERAL",
  PACKAGE_TERMINATION = "PACKAGE_TERMINATION",
  PACKAGE_UPDATE = "PACKAGE_UPDATE",
  REVIEW_SOLICITATION = "REVIEW_SOLICITATION",
  SKIP_VISIT = "SKIP_VISIT",
  UNSCHEDULED_VISIT = "UNSCHEDULED_VISIT",
  VISIT_RESCHEDULE = "VISIT_RESCHEDULE",
  WARRANTY = "WARRANTY",
}

export enum JobScheduleTypeEnum {
  ONE_TIME = "ONE_TIME",
  RECURRENT = "RECURRENT",
}

export enum JobSortByEnum {
  created_at = "created_at",
  end_date = "end_date",
  schedule_type = "schedule_type",
  start_date = "start_date",
  status = "status",
  updated_at = "updated_at",
}

export enum JobStatusEnum {
  ASSIGNED = "ASSIGNED",
  UNASSIGNED = "UNASSIGNED",
}

export enum LanguageSpokenEnum {
  BahasaIndonesia = "BahasaIndonesia",
  Bengali = "Bengali",
  Burmese = "Burmese",
  Chinese = "Chinese",
  English = "English",
  Hindi = "Hindi",
  Malay = "Malay",
  Punjabi = "Punjabi",
  Sinhala = "Sinhala",
  Tamil = "Tamil",
  Telagu = "Telagu",
  Vietnamese = "Vietnamese",
}

export enum LeaveApplicationSortByEnum {
  created_at = "created_at",
  end_date = "end_date",
  hours_applied = "hours_applied",
  leave_type = "leave_type",
  start_date = "start_date",
  status = "status",
  updated_at = "updated_at",
  worker_first_name = "worker_first_name",
  worker_last_name = "worker_last_name",
}

export enum LeaveApplicationStageEnum {
  HISTORY = "HISTORY",
  REQUESTED = "REQUESTED",
}

export enum LeaveApplicationStatusEnum {
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}

export enum LeaveTypeEnum {
  AL = "AL",
  CL = "CL",
  HL = "HL",
  NPL = "NPL",
  SL = "SL",
}

export enum ManagerRoleEnum {
  OPS_EXECUTIVE = "OPS_EXECUTIVE",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum ManagerStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING_OFFBOARDING = "PENDING_OFFBOARDING",
}

export enum MasterNotificationCategoryEnum {
  AUTHENTICATION = "AUTHENTICATION",
  BOOKING = "BOOKING",
  FEEDBACK_AND_REVIEW = "FEEDBACK_AND_REVIEW",
  INVITATION = "INVITATION",
  INVOICE = "INVOICE",
  JOBS = "JOBS",
  NEW_USER = "NEW_USER",
  PACKAGE_AND_VISIT = "PACKAGE_AND_VISIT",
  PAYMENT = "PAYMENT",
  PAYROLL = "PAYROLL",
  REFERRAL = "REFERRAL",
  REWARD = "REWARD",
  SPECIAL_OFFER_AND_PROMOTION = "SPECIAL_OFFER_AND_PROMOTION",
  VISITS = "VISITS",
  WORKER_UPSELLING = "WORKER_UPSELLING",
}

export enum MasterRewardSortByEnum {
  created_at = "created_at",
  name = "name",
  status = "status",
  updated_at = "updated_at",
}

export enum MasterRewardStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum MasterRewardSortByEnum {
  created_at = "created_at",
  name = "name",
  status = "status",
  updated_at = "updated_at",
}

export enum MasterRewardStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum NationalityEnum {
  Bangladeshi = "Bangladeshi",
  Burmese = "Burmese",
  Cambodian = "Cambodian",
  Chinese = "Chinese",
  Indian = "Indian",
  Indonesian = "Indonesian",
  Malaysian = "Malaysian",
  Singapore_PR = "Singapore_PR",
  Singaporean = "Singaporean",
  SriLankan = "SriLankan",
  Thai = "Thai",
  Vietnamese = "Vietnamese",
}

export enum NewRecurringClientSortByEnum {
  address = "address",
  client_name = "client_name",
  first_visit_date = "first_visit_date",
  manager_code = "manager_code",
  package_code = "package_code",
  worker_name = "worker_name",
}

export enum NotificationSortByEnum {
  channel = "channel",
  id = "id",
  name = "name",
  receiver = "receiver",
  related_object = "related_object",
}

export enum NotificationStatusEnum {
  canceled = "canceled",
  completed = "completed",
  delivered = "delivered",
  failed = "failed",
  pending = "pending",
  read = "read",
  retrying = "retrying",
  scheduled = "scheduled",
  sent = "sent",
  undelivered = "undelivered",
}

export enum PackageActionsEnum {
  DELETE = "DELETE",
}

export enum PackageDepartmentEnum {
  AIRCON = "AIRCON",
  BABYSITTER = "BABYSITTER",
  CARPET_UPHOLSTERY = "CARPET_UPHOLSTERY",
  CAR_WASH = "CAR_WASH",
  DEEP_CLEANING = "DEEP_CLEANING",
  ELDER_CARE = "ELDER_CARE",
  HANDYMAN = "HANDYMAN",
  HOME_BEAUTY = "HOME_BEAUTY",
  HOME_CLEANING = "HOME_CLEANING",
  MASSAGE = "MASSAGE",
  OFFICE_CLEANING = "OFFICE_CLEANING",
  PET_CARE = "PET_CARE",
}

export enum PackageDetailBillingUnitEnum {
  FIXED = "FIXED",
  HOURLY = "HOURLY",
  PER_SQFT = "PER_SQFT",
}

export enum PackageDetailSessionEnum {
  ALL = "ALL",
  DAY = "DAY",
  EVENING = "EVENING",
}

export enum PackageDetailSortByEnum {
  code = "code",
  created_at = "created_at",
  description = "description",
  duration = "duration",
  id = "id",
  updated_at = "updated_at",
}

export enum PackageRecurrenceEnum {
  AD_HOC = "AD_HOC",
  DAY = "DAY",
  FIXED_DW_HALF_YEAR = "FIXED_DW_HALF_YEAR",
  FIXED_DW_MONTH = "FIXED_DW_MONTH",
  FIXED_DW_QUARTER = "FIXED_DW_QUARTER",
  FORTNIGHT = "FORTNIGHT",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum PackageRequestSortByEnum {
  created_at = "created_at",
  first_name = "first_name",
  id = "id",
  last_name = "last_name",
  package_code = "package_code",
  start_date = "start_date",
  status = "status",
  updated_at = "updated_at",
}

export enum PackageRequestStatusEnum {
  CONFIRMED = "CONFIRMED",
  CREATED = "CREATED",
  EXPIRED = "EXPIRED",
  PENDING_CLIENT_CONFIRMATION = "PENDING_CLIENT_CONFIRMATION",
  REJECTED = "REJECTED",
  SENT_TO_ALL_WORKERS = "SENT_TO_ALL_WORKERS",
  SENT_TO_PRIORITY_WORKERS = "SENT_TO_PRIORITY_WORKERS",
}

export enum PackageServiceTypeEnum {
  ADD_ON = "ADD_ON",
  ADJUSTMENT = "ADJUSTMENT",
  AD_HOC = "AD_HOC",
  AIRCON = "AIRCON",
  BABYSITTING = "BABYSITTING",
  BODY_MASSAGE = "BODY_MASSAGE",
  CARPET_UPHOLSTERY = "CARPET_UPHOLSTERY",
  CHEMICAL_OVERHAUL = "CHEMICAL_OVERHAUL",
  CHEMICAL_WASH = "CHEMICAL_WASH",
  COMBO = "COMBO",
  CONDENSER_WASH = "CONDENSER_WASH",
  DEEP_CLEANING = "DEEP_CLEANING",
  DEEP_EXTRACTION = "DEEP_EXTRACTION",
  DISCOUNT = "DISCOUNT",
  DISMANTLING = "DISMANTLING",
  ELDER_CARE = "ELDER_CARE",
  ELECTRICIAN = "ELECTRICIAN",
  EQUIPMENT = "EQUIPMENT",
  FEE = "FEE",
  FOOT_MASSAGE = "FOOT_MASSAGE",
  GAS_LEAK_TESTING = "GAS_LEAK_TESTING",
  GAS_TOP_UP = "GAS_TOP_UP",
  GENERAL_SERVICING = "GENERAL_SERVICING",
  HARDWARE = "HARDWARE",
  HOME_CLEANING = "HOME_CLEANING",
  INSTALLATION = "INSTALLATION",
  JET_WASH = "JET_WASH",
  LASHES = "LASHES",
  MANICURE = "MANICURE",
  MASSAGE = "MASSAGE",
  MPV = "MPV",
  OFFICE_CLEANING = "OFFICE_CLEANING",
  PEDICURE = "PEDICURE",
  PET_CARE = "PET_CARE",
  PLUMBING = "PLUMBING",
  REPAIR_DIAGNOSTIC = "REPAIR_DIAGNOSTIC",
  REPAIR_FOLLOW_UP = "REPAIR_FOLLOW_UP",
  SEDAN = "SEDAN",
  STEAM_CLEANING = "STEAM_CLEANING",
  SUV = "SUV",
  ULTRA_VIOLET_CLEANING = "ULTRA_VIOLET_CLEANING",
  UPGRADE = "UPGRADE",
  WARRANTY = "WARRANTY",
}

export enum PackageStatusEnum {
  ACTIVE = "ACTIVE",
  CREATED = "CREATED",
  FAILED = "FAILED",
  TERMINATED = "TERMINATED",
}

export enum PackageTerminationCategoryEnum {
  PACKAGE_CHANGE = "PACKAGE_CHANGE",
  TERMINATION = "TERMINATION",
}

export enum PackageTerminationReasonEnum {
  CLIENT_TERMINATION = "CLIENT_TERMINATION",
  CLIENT_WILL_SELF_CLEAN = "CLIENT_WILL_SELF_CLEAN",
  FREQUENT_CANCELLATION = "FREQUENT_CANCELLATION",
  GOING_OVERSEAS = "GOING_OVERSEAS",
  HIRED_FULL_TIME_CLEANER = "HIRED_FULL_TIME_CLEANER",
  MOVING_OUT = "MOVING_OUT",
  NON_PAYING_CLIENT = "NON_PAYING_CLIENT",
  OPS_INITIATED = "OPS_INITIATED",
  OPS_TERMINATED = "OPS_TERMINATED",
  OTHERS = "OTHERS",
  PACKAGE_CHANGE = "PACKAGE_CHANGE",
  POOR_CLEANING_QUALITY = "POOR_CLEANING_QUALITY",
  PRICING_ISSUE = "PRICING_ISSUE",
  SERVICE_FRUSTRATIONS = "SERVICE_FRUSTRATIONS",
  SWITCHED_TO_COMPETITOR = "SWITCHED_TO_COMPETITOR",
  TEMPORARY_CLEANING_ONLY = "TEMPORARY_CLEANING_ONLY",
  UNWILLING_TO_SHARE_REASON = "UNWILLING_TO_SHARE_REASON",
}

export enum PackageUnitTypeEnum {
  ALL = "ALL",
  BABY_PRAM = "BABY_PRAM",
  BODY_MASSAGE = "BODY_MASSAGE",
  CARPET = "CARPET",
  CAT = "CAT",
  CEILING = "CEILING",
  CHILD_SEAT = "CHILD_SEAT",
  CURTAIN = "CURTAIN",
  DOG = "DOG",
  DUCTED = "DUCTED",
  ELECTRICIAN = "ELECTRICIAN",
  FOOT_MASSAGE = "FOOT_MASSAGE",
  HANDYMAN = "HANDYMAN",
  INSTALLATION = "INSTALLATION",
  LASHES = "LASHES",
  MATTRESS = "MATTRESS",
  MPV = "MPV",
  NAIL = "NAIL",
  PILLOW = "PILLOW",
  PLUMBING = "PLUMBING",
  PORTABLE = "PORTABLE",
  R22 = "R22",
  R410A = "R410A",
  RUG = "RUG",
  SEDAN = "SEDAN",
  SOFA = "SOFA",
  SUV = "SUV",
  WALL = "WALL",
  WINDOW = "WINDOW",
}

export enum PaymentProofStatusEnum {
  NOT_UPLOADED = "NOT_UPLOADED",
  UPLOADED = "UPLOADED",
}

export enum PaymentTransactionModesEnum {
  BANK_TRANSFER = "BANK_TRANSFER",
  CASH = "CASH",
  CHEQUE = "CHEQUE",
}

export enum PaymentTransactionReasonsEnum {
  BULK_SALARY_PAYMENT = "BULK_SALARY_PAYMENT",
  MANUAL_PAYMENT = "MANUAL_PAYMENT",
  OTHERS = "OTHERS",
  SALARY_ADVANCE = "SALARY_ADVANCE",
}

export enum PayrollPaymentStatusEnum {
  OVERPAID = "OVERPAID",
  PAID = "PAID",
  UNDERPAID = "UNDERPAID",
  UNPAID = "UNPAID",
}

export enum PayrollSortByEnum {
  billing_account_worker_first_name = "billing_account_worker_first_name",
  billing_account_worker_last_name = "billing_account_worker_last_name",
  created_at = "created_at",
  gross = "gross",
  id = "id",
  nett = "nett",
  payment_status = "payment_status",
  updated_at = "updated_at",
}

export enum PayrollStatusEnum {
  CANCELLED = "CANCELLED",
  CONFIRMED = "CONFIRMED",
  NEW = "NEW",
}

export enum PolicyTypeEnum {
  ALLOWANCE = "ALLOWANCE",
  BONUS = "BONUS",
  DEDUCTION = "DEDUCTION",
  OVERTIME = "OVERTIME",
  UNIT_VALUE = "UNIT_VALUE",
}

export enum ProjectedPayrollSortByEnum {
  billing_account_worker_first_name = "billing_account_worker_first_name",
  billing_account_worker_last_name = "billing_account_worker_last_name",
  created_at = "created_at",
  gmv = "gmv",
  gross = "gross",
  id = "id",
  nett = "nett",
  updated_at = "updated_at",
}

export enum PromotionSortByEnum {
  end_date = "end_date",
  start_date = "start_date",
  title = "title",
}

export enum PromotionStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ReceiverEnum {
  CLIENT = "CLIENT",
  OPS = "OPS",
  WORKER = "WORKER",
}

export enum RelatedObjectEnum {
  BILLING = "BILLING",
  CLIENT = "CLIENT",
  CONTRACT = "CONTRACT",
  JOB = "JOB",
  PIN_CODE = "PIN_CODE",
  REFERRAL = "REFERRAL",
  REWARD = "REWARD",
  UPSELLING = "UPSELLING",
  USER = "USER",
  VISIT = "VISIT",
  WORKER_REFERRAL = "WORKER_REFERRAL",
}

export enum ResetPasswordEnum {
  CLAIM_ACCOUNT = "CLAIM_ACCOUNT",
  CONFIRM_EMAIL = "CONFIRM_EMAIL",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}

export enum RewardSortByEnum {
  created_at = "created_at",
  status = "status",
  updated_at = "updated_at",
}

export enum RewardStatusEnum {
  AVAILABLE = "AVAILABLE",
  CANCELLED = "CANCELLED",
  EARNED = "EARNED",
  USED = "USED",
}

export enum SalesAgentCommissionSortByEnum {
  customer_first_name = "customer_first_name",
  customer_last_name = "customer_last_name",
  id = "id",
}

export enum ScheduleJobFrequencyEnum {
  AD_HOC = "AD_HOC",
  FORTNIGHT = "FORTNIGHT",
  WEEKLY = "WEEKLY",
  YEAR = "YEAR",
}

export enum ScheduleMatchingEnum {
  ALL = "ALL",
  ANY = "ANY",
}

export enum ServiceTypeEnum {
  ADD_ON = "ADD_ON",
  ADJUSTMENT = "ADJUSTMENT",
  AD_HOC = "AD_HOC",
  AIRCON = "AIRCON",
  BABYSITTING = "BABYSITTING",
  BODY_MASSAGE = "BODY_MASSAGE",
  CARPET_UPHOLSTERY = "CARPET_UPHOLSTERY",
  CHEMICAL_OVERHAUL = "CHEMICAL_OVERHAUL",
  CHEMICAL_WASH = "CHEMICAL_WASH",
  COMBO = "COMBO",
  CONDENSER_WASH = "CONDENSER_WASH",
  DEEP_CLEANING = "DEEP_CLEANING",
  DEEP_EXTRACTION = "DEEP_EXTRACTION",
  DISCOUNT = "DISCOUNT",
  DISMANTLING = "DISMANTLING",
  ELDER_CARE = "ELDER_CARE",
  ELECTRICIAN = "ELECTRICIAN",
  EQUIPMENT = "EQUIPMENT",
  FEE = "FEE",
  FOOT_MASSAGE = "FOOT_MASSAGE",
  GAS_LEAK_TESTING = "GAS_LEAK_TESTING",
  GAS_TOP_UP = "GAS_TOP_UP",
  GENERAL_SERVICING = "GENERAL_SERVICING",
  HARDWARE = "HARDWARE",
  HOME_CLEANING = "HOME_CLEANING",
  INSTALLATION = "INSTALLATION",
  JET_WASH = "JET_WASH",
  LASHES = "LASHES",
  MANICURE = "MANICURE",
  MASSAGE = "MASSAGE",
  MPV = "MPV",
  OFFICE_CLEANING = "OFFICE_CLEANING",
  PEDICURE = "PEDICURE",
  PET_CARE = "PET_CARE",
  PLUMBING = "PLUMBING",
  REPAIR_DIAGNOSTIC = "REPAIR_DIAGNOSTIC",
  REPAIR_FOLLOW_UP = "REPAIR_FOLLOW_UP",
  SEDAN = "SEDAN",
  STEAM_CLEANING = "STEAM_CLEANING",
  SUV = "SUV",
  ULTRA_VIOLET_CLEANING = "ULTRA_VIOLET_CLEANING",
  UPGRADE = "UPGRADE",
  WARRANTY = "WARRANTY",
}

export enum SessionStatusEnum {
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  SCHEDULED = "SCHEDULED",
}

export enum SessionTypeEnum {
  BREAK = "BREAK",
  TASK = "TASK",
  VISIT = "VISIT",
}

export enum SexEnum {
  Female = "Female",
  Male = "Male",
  NotDecided = "NotDecided",
}

export enum SignInProviderEnum {
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  PASSWORD = "PASSWORD",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export enum SortByEnum {
  discount_type = "discount_type",
  discount_value = "discount_value",
  expiration_date = "expiration_date",
  first_transaction_only = "first_transaction_only",
  promo_code = "promo_code",
}

export enum SortEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum StatusTypeEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum TaskPaymentTypesEnum {
  CONTRACT_PAYMENT = "CONTRACT_PAYMENT",
  CUSTOM_PAYMENT = "CUSTOM_PAYMENT",
  NO_PAYMENT = "NO_PAYMENT",
}

export enum TransactionStatusEnum {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  DELETED = "DELETED",
}

export enum TransactionTypeEnum {
  ALLOWANCE = "ALLOWANCE",
  BONUS = "BONUS",
  DEDUCTION = "DEDUCTION",
  DISCOUNT_LINE_ITEM = "DISCOUNT_LINE_ITEM",
  INVOICE = "INVOICE",
  OVERTIME = "OVERTIME",
  PACKAGE_LINE_ITEM = "PACKAGE_LINE_ITEM",
  PAYROLL = "PAYROLL",
  PAYROLL_ADJUSTMENT = "PAYROLL_ADJUSTMENT",
  PAYROLL_PAYMENT = "PAYROLL_PAYMENT",
  TASK = "TASK",
  TOP_UP = "TOP_UP",
  VISIT = "VISIT",
  VISIT_LINE_ITEM = "VISIT_LINE_ITEM",
  VOUCHER_DISCOUNT = "VOUCHER_DISCOUNT",
}

export enum TriggerEnum {
  AUTOMATIC_WORKER = "AUTOMATIC_WORKER",
  EVENT = "EVENT",
}

export enum UnitSizeEnum {
  ALL = "ALL",
  ARM_CHAIR = "ARM_CHAIR",
  BABY_COT = "BABY_COT",
  CHAIR = "CHAIR",
  C_250SQFT_ABOVE = "C_250SQFT_ABOVE",
  C_4000SQFT_ABOVE = "C_4000SQFT_ABOVE",
  C_600SQFT_BELOW = "C_600SQFT_BELOW",
  C_601SQFT_TO_900SQFT = "C_601SQFT_TO_900SQFT",
  C_901SQFT_TO_1500SQFT = "C_901SQFT_TO_1500SQFT",
  EXTRA_LARGE = "EXTRA_LARGE",
  KING = "KING",
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  QUEEN = "QUEEN",
  SEATER_1 = "SEATER_1",
  SEATER_2 = "SEATER_2",
  SEATER_3 = "SEATER_3",
  SEATER_4 = "SEATER_4",
  SEATER_5 = "SEATER_5",
  SINGLE = "SINGLE",
  SMALL = "SMALL",
  SUPER_KING = "SUPER_KING",
  SUPER_SINGLE = "SUPER_SINGLE",
}

export enum UserDeviceEnum {
  ANDROID = "ANDROID",
  BROWSER = "BROWSER",
  IOS = "IOS",
  OTHERS = "OTHERS",
  SYSTEM = "SYSTEM",
}

export enum UserEnum {
  Client = "Client",
  Regular = "Regular",
  Worker = "Worker",
}

export enum UserPlatformEnum {
  CLIENT_APP = "CLIENT_APP",
  CLIENT_WEB = "CLIENT_WEB",
  INTERNAL_WEB = "INTERNAL_WEB",
  SCRIPT = "SCRIPT",
  WORKER_APP = "WORKER_APP",
}

export enum VisitAssignmentEnum {
  ASSIGNED = "ASSIGNED",
  REPLACED = "REPLACED",
  UNASSIGNED = "UNASSIGNED",
}

export enum VisitBillingAdjustmentReasonTypes {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum VisitBillingAdjustmentTypes {
  ADDON = "ADDON",
  DEDUCTION = "DEDUCTION",
  DISCOUNT = "DISCOUNT",
}

export enum VisitPayrollAdjustmentReasonTypes {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum VisitPayrollAdjustmentTypes {
  ADDON = "ADDON",
  DEDUCTION = "DEDUCTION",
}

export enum VisitSortByEnum {
  client_billing_value = "client_billing_value",
  created_at = "created_at",
  end_time = "end_time",
  id = "id",
  rating = "rating",
  service_date = "service_date",
  start_time = "start_time",
  status = "status",
  task_package_address_full_address = "task_package_address_full_address",
  task_package_contract_client_name = "task_package_contract_client_name",
  updated_at = "updated_at",
  worker_first_name = "worker_first_name",
  worker_last_name = "worker_last_name",
}

export enum VisitStatusEnum {
  CANCELLED_BY_CLIENT = "CANCELLED_BY_CLIENT",
  CANCELLED_BY_OPS = "CANCELLED_BY_OPS",
  CANCELLED_BY_WORKER = "CANCELLED_BY_WORKER",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  FINISHED = "FINISHED",
  MISSED_BY_WORKER = "MISSED_BY_WORKER",
  PENDING_CLIENT_SCHEDULE = "PENDING_CLIENT_SCHEDULE",
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  UNABLE_TO_SCHEDULE = "UNABLE_TO_SCHEDULE",
}

export enum VoucherDiscountTypeEnum {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export enum VoucherStatusEnum {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  REDEEMED = "REDEEMED",
}

export enum WorkerAttendanceSortByEnum {
  attendance_status = "attendance_status",
  created_at = "created_at",
  submitted_date = "submitted_date",
  updated_at = "updated_at",
  worker_deparment = "worker_deparment",
  worker_first_name = "worker_first_name",
  worker_last_name = "worker_last_name",
}

export enum WorkerAttendanceStatusEnum {
  MISSED = "MISSED",
  NO_JOB = "NO_JOB",
  SUBMITTED = "SUBMITTED",
}

export enum WorkerContractDayTypeEnum {
  ENHANCED_WORKING_OFF_DAY = "ENHANCED_WORKING_OFF_DAY",
  NON_WORKING_OFF_DAY = "NON_WORKING_OFF_DAY",
  REGULAR_WORKING_DAY = "REGULAR_WORKING_DAY",
  STANDARD_WORKING_OFF_DAY = "STANDARD_WORKING_OFF_DAY",
}

export enum WorkerContractStatusEnum {
  ACTIVE = "ACTIVE",
  TERMINATED = "TERMINATED",
}

export enum WorkerContractWeekTypeEnum {
  ALL = "ALL",
  EVEN = "EVEN",
  ODD = "ODD",
}

export enum WorkerDepartmentEnum {
  AIRCON = "AIRCON",
  BABYSITTER = "BABYSITTER",
  CARPET_UPHOLSTERY = "CARPET_UPHOLSTERY",
  CAR_WASH = "CAR_WASH",
  DEEP_CLEANING = "DEEP_CLEANING",
  ELDER_CARE = "ELDER_CARE",
  HANDYMAN = "HANDYMAN",
  HOME_BEAUTY = "HOME_BEAUTY",
  HOME_CLEANING = "HOME_CLEANING",
  MASSAGE = "MASSAGE",
  OFFICE_CLEANING = "OFFICE_CLEANING",
  PET_CARE = "PET_CARE",
}

export enum WorkerDocumentTypeEnum {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  CERTIFICATE_VACCINATION = "CERTIFICATE_VACCINATION",
  NRIC_IC = "NRIC_IC",
  OTHERS = "OTHERS",
  PASSPORT = "PASSPORT",
  RESIGNATION_LETTER = "RESIGNATION_LETTER",
  TERMINATION_LETTER = "TERMINATION_LETTER",
  TRAINING_CERTIFICATE = "TRAINING_CERTIFICATE",
  WOKER_CONTRACT = "WOKER_CONTRACT",
  WORK_PERMIT = "WORK_PERMIT",
}

export enum WorkerModeOfTransportEnum {
  BIKE = "BIKE",
  CAR = "CAR",
  PUBLIC_TRANSPORT = "PUBLIC_TRANSPORT",
}

export enum WorkerSortByEnum {
  contact_number = "contact_number",
  created_at = "created_at",
  department = "department",
  first_name = "first_name",
  last_name = "last_name",
  last_working_day = "last_working_day",
  nationality = "nationality",
  status = "status",
  updated_at = "updated_at",
  worker_rating = "worker_rating",
}

export enum WorkerStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum WorkerStatusReferralEnum {
  CANCELLED = "CANCELLED",
  PAID = "PAID",
  UNPAID = "UNPAID",
}

export enum WorkerTaskSortByEnum {
  created_at = "created_at",
  id = "id",
  status = "status",
  task_date = "task_date",
  updated_at = "updated_at",
}

export enum WorkerTaskStatusesEnum {
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  SCHEDULED = "SCHEDULED",
}

export enum WorkerTipSortByEnum {
  created_at = "created_at",
  department = "department",
  nationality = "nationality",
  updated_at = "updated_at",
}

export enum WorkerWorkerTypeEnum {
  CLEANER = "CLEANER",
  TECHNICIAN = "TECHNICIAN",
}

export enum XeroHistoryItemTypesEnum {
  INVOICE = "INVOICE",
  PAYMENT = "PAYMENT",
}

export enum XeroInvoiceStatusEnum {
  AUTHORISED = "AUTHORISED",
  DELETED = "DELETED",
  DRAFT = "DRAFT",
  PAID = "PAID",
  VOIDED = "VOIDED",
}

export enum acknowledgementTypeEnum {
  BREAK_TIME_ACKNOWLEDGEMENT = "BREAK_TIME_ACKNOWLEDGEMENT",
  EARLIER_COMPLETED_VISIT = "EARLIER_COMPLETED_VISIT",
  WORKING_TIME_ACKNOWLEDGEMENT = "WORKING_TIME_ACKNOWLEDGEMENT",
}

export interface AddressInput {
  fullAddress: string;
  postalCode: string;
  unitNumber?: string | null;
  primary?: boolean | null;
}

export interface AllNewRecurringClientsFilter {
  managerId?: string | null;
  sortBy?: NewRecurringClientSortByEnum[] | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
}

export interface AllWorkersFilter {
  status?: WorkerStatusEnum | null;
  search?: string | null;
  managerId?: string | null;
  department?: WorkerDepartmentEnum[] | null;
  workerSkillIds?: string[] | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: WorkerSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface ChangeHistoriesFilter {
  id: string;
  type: AuditableTypesEnum;
  limit?: number | null;
  offset?: number | null;
  sortBy?: ChangeHistorySortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface ClientConversationsInput {
  clientId: string;
  limit?: number | null;
  offset?: number | null;
  sortBy?: ClientConversationSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface ClientCrmByFiltersFilter {
  clientId: string;
  query?: string | null;
  stage?: ClientCrmStageEnum | null;
  limit?: number | null;
  offset?: number | null;
}

export interface ClientReferralFilter {
  referrerId?: string | null;
  status?: ClientStatusReferralEnum[] | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: ClientReferralSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface ClientsFilter {
  status?: ClientStatusEnum[] | null;
  accountType?: ClientAccountTypeEnum | null;
  search?: string | null;
  sortBy?: ClientSortByEnum[] | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
}

/**
 * Confirmation of actions by user
 */
export interface ConfirmationType {
  key: string;
  confirmed?: boolean | null;
  message?: string | null;
}

export interface ContactInput {
  firstName: string;
  lastName: string;
  email: string[];
  phoneNumber: string[];
  primary?: boolean | null;
}

/**
 * Input for creation source
 */
export interface CreationSourceInput {
  platform?: UserPlatformEnum | null;
  device?: UserDeviceEnum | null;
}

export interface CreditAccountsFilter {
  creditType?: CreditTypeEnum[] | null;
  status?: CreditAccountStatusEnum[] | null;
  clientId?: string[] | null;
  sortBy?: CreditAccountSortByEnum[] | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
}

/**
 * Input for device-specific data
 */
export interface DeviceInput {
  uid?: string | null;
  appVersion?: string | null;
  timezone?: string | null;
  locale?: string | null;
  platform?: string | null;
  platformVersion?: string | null;
}

/**
 * Input for expo token
 */
export interface ExpoTokenInput {
  token?: string | null;
}

/**
 * Input for current installation (= device)
 */
export interface InstallationInput {
  pushNotificationTokens?: PushNotificationTokensInput | null;
  device?: DeviceInput | null;
}

export interface InvoicesFilter {
  status?: InvoiceStatusEnum[] | null;
  paymentStatus?: InvoicePaymentStatusEnum[] | null;
  toDate?: any | null;
  issueDateFrom?: any | null;
  issueDateTo?: any | null;
  fromDate?: any | null;
  fromDateFrom?: any | null;
  fromDateTo?: any | null;
  toDateFrom?: any | null;
  toDateTo?: any | null;
  overdueDaysFrom?: number | null;
  overdueDaysTo?: number | null;
  paymentProofStatus?: PaymentProofStatusEnum | null;
  emailStatus?: EmailStatusEnum | null;
  search?: string | null;
  clientId?: string[] | null;
  managerId?: string[] | null;
  sortBy?: InvoiceSortByEnum[] | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
  department?: PackageDepartmentEnum | null;
}

export interface InvoicingInput {
  purchaseOrderNumber?: string | null;
  specialInvoicingInstructions?: string | null;
  billingTerms: ContractBillingTermsEnum;
  invoiceFrequency: ContractInvoiceFrequencyEnum;
  autoConfirmInvoice: boolean;
  autoSendInvoiceEmail: boolean;
}

export interface JobInput {
  workerId?: string | null;
  workerContractId?: string | null;
  startDate: any;
  endDate?: any | null;
  tasks: JobTaskInput[];
}

export interface JobTaskInput {
  id: string;
}

export interface JobsFilter {
  status?: JobStatusEnum[] | null;
  scheduleType?: JobScheduleTypeEnum[] | null;
  serviceType?: PackageServiceTypeEnum[] | null;
  startDateFrom?: any | null;
  startDateTo?: any | null;
  endDateFrom?: any | null;
  endDateTo?: any | null;
  department?: PackageDepartmentEnum | null;
  managerId?: string | null;
  workerId?: string[] | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: JobSortByEnum[] | null;
  sort?: SortEnum[] | null;
  id?: string | null;
  search?: string | null;
}

export interface LeaveApplicationsFilter {
  workerIds?: string[] | null;
  managerIds?: string[] | null;
  hoursApplied?: number | null;
  status?: LeaveApplicationStatusEnum[] | null;
  startDateFrom?: any | null;
  startDateTo?: any | null;
  department?: WorkerDepartmentEnum[] | null;
  nationality?: NationalityEnum[] | null;
  search?: string | null;
  leaveType?: LeaveTypeEnum[] | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: LeaveApplicationSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface LineItemInput {
  lineItemId: string;
  units: number;
  unitValue: number;
  description?: string | null;
}

export interface ManagerByFiltersInput {
  role?: ManagerRoleEnum | null;
  status?: ManagerStatusEnum[] | null;
}

export interface ManagerRoleByFiltersInput {
  name?: ManagerRoleEnum | null;
}

export interface MasterNotificationFilter {
  name?: string | null;
  receiver?: ReceiverEnum | null;
  channel?: ChannelEnum | null;
  relatedObject?: string | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: NotificationSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface MasterPromoCodeFilter {
  promoCode?: string | null;
  discountType?: DiscountTypeEnum | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: SortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface MasterRewardsFilter {
  status?: MasterRewardStatusEnum[] | null;
  incentiveType?: IncentiveTypesEnum | null;
  search?: string | null;
  sortBy?: MasterRewardSortByEnum[] | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
}

export interface NotificationHistoryFilter {
  templateId?: string | null;
  userName?: string | null;
  notificationName?: string | null;
  receiverId?: string | null;
  receiverType?: ReceiverEnum | null;
  status?: NotificationStatusEnum | null;
  category?: MasterNotificationCategoryEnum | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: LeaveApplicationSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface OffboardingsFilterInput {
  department?: WorkerDepartmentEnum | null;
  nameLike?: string | null;
  managerId?: string | null;
  sortBy?: WorkerSortByEnum[] | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
}

/**
 * Input for OneSignal classic token
 */
export interface OneSignalClassicTokenInput {
  playerId: string;
}

/**
 * Input for OneSignal VoIP token
 */
export interface OneSignalVoipTokenInput {
  playerId: string;
}

export interface PackageDetailsFilter {
  search?: string | null;
  department?: PackageDepartmentEnum | null;
  repeatEvery?: PackageRecurrenceEnum[] | null;
  repeatEveryTimes?: number | null;
  duration?: number | null;
  durationPerUnit?: number | null;
  session?: PackageDetailSessionEnum | null;
  description?: string | null;
  active?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: PackageDetailSortByEnum[] | null;
  sort?: SortEnum[] | null;
  unitTypes?: PackageUnitTypeEnum[] | null;
  serviceTypes?: ServiceTypeEnum[] | null;
}

export interface PackageLineItemInput {
  packageDetailId: string;
  packageDescription: string;
  billingUnit: PackageDetailBillingUnitEnum;
  unitValue: number;
  units?: number | null;
}

export interface PackageRequestsFilter {
  search?: string | null;
  status?: PackageRequestStatusEnum | null;
  packageCode?: string | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: PackageRequestSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface PayrollAdjustmentsFilter {
  startDate?: any | null;
  endDate?: any | null;
  startDateFrom?: any | null;
  startDateTo?: any | null;
  endDateFrom?: any | null;
  endDateTo?: any | null;
  id?: string[] | null;
  workerId?: string[] | null;
  billingAccountId?: string[] | null;
  limit?: number | null;
  offset?: number | null;
  cpfContributable?: boolean | null;
}

export interface PayrollPolicyInput {
  payrollPolicyId: number;
  payload: PayrollPolicyPayloadInput[];
}

export interface PayrollPolicyPayloadInput {
  name?: string | null;
  min?: number | null;
  max?: number | null;
  deduction?: number | null;
  value?: number | null;
}

export interface PayrollsFilter {
  paymentStatus?: PayrollPaymentStatusEnum[] | null;
  status?: PayrollStatusEnum[] | null;
  toDate?: any | null;
  fromDate?: any | null;
  fromDateFrom?: any | null;
  fromDateTo?: any | null;
  toDateFrom?: any | null;
  toDateTo?: any | null;
  workerId?: string[] | null;
  managerId?: string[] | null;
  workerAcknowledgement?: boolean | null;
  search?: string | null;
  sortBy?: PayrollSortByEnum[] | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
}

export interface ProjectedPayrollFilterInput {
  workerId?: string[] | null;
  managerId?: string[] | null;
  search?: string | null;
  sortBy?: ProjectedPayrollSortByEnum[] | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
}

export interface PromotionFilter {
  promotionId?: string | null;
  search?: string | null;
  status?: PromotionStatusEnum | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: PromotionSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

/**
 * Input for push notification tokens
 */
export interface PushNotificationTokensInput {
  oneSignalClassic?: OneSignalClassicTokenInput | null;
  oneSignalVoip?: OneSignalVoipTokenInput | null;
  expo?: ExpoTokenInput | null;
}

export interface RewardsFilter {
  userId?: string | null;
  masterRewardId?: string | null;
  incentiveType?: IncentiveTypesEnum | null;
  status?: RewardStatusEnum[] | null;
  search?: string | null;
  sortBy?: RewardSortByEnum[] | null;
  validTo?: any | null;
  sort?: SortEnum[] | null;
  limit?: number | null;
  offset?: number | null;
}

export interface ServicesByFiltersInput {
  departmentName?: string | null;
}

export interface TaskInput {
  day: number;
  startTime?: string | null;
  endTime?: string | null;
  workingHours?: TaskWorkingHoursInput[] | null;
}

export interface TaskWorkingHoursInput {
  startTime: string;
  endTime: string;
}

export interface TimeRange {
  day: number;
  startTime: string;
  endTime: string;
}

export interface TransactionsFilter {
  transactionType?: TransactionTypeEnum[] | null;
  status?: TransactionStatusEnum[] | null;
  billingAccountId?: string[] | null;
  billingCycleId?: string[] | null;
  limit?: number | null;
  offset?: number | null;
}

export interface UserUpdateInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
}

export interface UtmInput {
  source?: string | null;
  medium?: string | null;
  campaign?: string | null;
  term?: string | null;
  content?: string | null;
}

export interface VisitAcknowledgementFilter {
  workerId?: string | null;
  visitId?: string | null;
  upcomingVisitId?: string | null;
  acknowledgementType?: acknowledgementTypeEnum | null;
  serviceDateFrom?: any | null;
  serviceDateTo?: any | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: VisitSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface VisitLineItemInput {
  id?: string | null;
  packageDetailId: string;
  packageDescription: string;
  billingUnit: PackageDetailBillingUnitEnum;
  units: number;
  unitValue: number;
}

export interface VisitReviewsFilter {
  workerId?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  rating?: number | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: VisitSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface VisitsFilter {
  status?: VisitStatusEnum[] | null;
  assignment?: VisitAssignmentEnum[] | null;
  serviceType?: PackageServiceTypeEnum[] | null;
  serviceDateFrom?: any | null;
  serviceDateTo?: any | null;
  workerDepartment?: WorkerDepartmentEnum[] | null;
  department?: PackageDepartmentEnum | null;
  managerId?: string | null;
  workerId?: string[] | null;
  search?: string | null;
  jobId?: string[] | null;
  clientId?: string[] | null;
  limit?: number | null;
  offset?: number | null;
  from?: any | null;
  to?: any | null;
  sortBy?: VisitSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface VouchersFilter {
  status?: VoucherStatusEnum | null;
  clientId?: string[] | null;
  search?: string | null;
  limit?: number | null;
  offset?: number | null;
}

export interface WorkerContractDayInput {
  day: number;
  endTime: string;
  startTime: string;
  weekType: WorkerContractWeekTypeEnum;
  restDay: boolean;
  dayType: WorkerContractDayTypeEnum;
}

export interface WorkerContractDetailsInputFilter {
  contractType?: ContractTypeEnum | null;
  status?: StatusTypeEnum | null;
}

export interface WorkerTasksFilter {
  taskDateFrom?: any | null;
  taskDateTo?: any | null;
  workerId?: string[] | null;
  workerContractId?: string[] | null;
  status?: WorkerTaskStatusesEnum[] | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: WorkerTaskSortByEnum[] | null;
  sort?: SortEnum[] | null;
}

export interface WorkerTipFilterInput {
  nationality?: NationalityEnum | null;
  department?: WorkerDepartmentEnum | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: WorkerTipSortByEnum[] | null;
  sort?: SortEnum[] | null;
  search?: string | null;
}

/**
 * Autogenerated input type of addClientDocuments
 */
export interface addClientDocumentsInput {
  clientMutationId?: string | null;
  clientId: string;
  files: any[];
}

/**
 * Autogenerated input type of addContractDocument
 */
export interface addContractDocumentInput {
  clientMutationId?: string | null;
  contractId: string;
  file: any;
}

/**
 * Autogenerated input type of addPaymentProof
 */
export interface addPaymentProofInput {
  clientMutationId?: string | null;
  invoiceId: string;
  file: any;
}

/**
 * Autogenerated input type of addPortfolios
 */
export interface addPortfoliosInput {
  clientMutationId?: string | null;
  workerContractId: string;
  files: any[];
}

/**
 * Autogenerated input type of addProofOfAgreements
 */
export interface addProofOfAgreementsInput {
  clientMutationId?: string | null;
  contractId: string;
  files: any[];
}

/**
 * Autogenerated input type of addStaffReviewAttachment
 */
export interface addStaffReviewAttachmentInput {
  clientMutationId?: string | null;
  visitId: string;
  files: any[];
}

/**
 * Autogenerated input type of addVisitLineItem
 */
export interface addVisitLineItemInput {
  clientMutationId?: string | null;
  visitId: string;
  packageDetailId: string;
  packageDescription: string;
  billingUnit: PackageDetailBillingUnitEnum;
  unitValue: number;
}

/**
 * Autogenerated input type of addVisitNoteAttachments
 */
export interface addVisitNoteAttachmentsInput {
  clientMutationId?: string | null;
  visitId: string;
  files: any[];
}

/**
 * Autogenerated input type of addWorkerDocument
 */
export interface addWorkerDocumentInput {
  clientMutationId?: string | null;
  workerId: string;
  docType: WorkerDocumentTypeEnum;
  docNumber?: string | null;
  issuanceDate?: any | null;
  expirationDate?: any | null;
  additionalDescription: string;
  files: any[];
}

/**
 * Autogenerated input type of applyPayroll
 */
export interface applyPayrollInput {
  clientMutationId?: string | null;
  payrollId: string;
  amount: number;
  transferMode: PaymentTransactionModesEnum;
  paymentReason: PaymentTransactionReasonsEnum;
  referenceNumber?: string | null;
}

/**
 * Autogenerated input type of approveLeaveApplication
 */
export interface approveLeaveApplicationInput {
  clientMutationId?: string | null;
  leaveApplicationId: string;
}

/**
 * Autogenerated input type of assignJob
 */
export interface assignJobInput {
  clientMutationId?: string | null;
  jobId: string;
  workerId?: string | null;
  workerContractId?: string | null;
  allowOverlap?: boolean | null;
}

/**
 * Autogenerated input type of assignPackageRequest
 */
export interface assignPackageRequestInput {
  clientMutationId?: string | null;
  packageRequestId: string;
  workerId: string;
  workerContractId?: string | null;
}

/**
 * Autogenerated input type of assignVisit
 */
export interface assignVisitInput {
  clientMutationId?: string | null;
  visitId: string;
  workerId?: string | null;
  workerContractId?: string | null;
  workerPayrollValue?: number | null;
  allowOverlap?: boolean | null;
}

/**
 * Autogenerated input type of cancelVisit
 */
export interface cancelVisitInput {
  clientMutationId?: string | null;
  visitId: string;
  reason?: CancelVisitReasonEnum | null;
}

/**
 * Autogenerated input type of changePackage
 */
export interface changePackageInput {
  clientMutationId?: string | null;
  packageId: string;
  repeatEveryTimes: number;
  lineItems: LineItemInput[];
  startDate?: any | null;
  startTime?: string | null;
}

/**
 * Autogenerated input type of clientRescheduleVisit
 */
export interface clientRescheduleVisitInput {
  clientMutationId?: string | null;
  id: string;
  serviceDate?: any | null;
  startTime?: string | null;
  endTime?: string | null;
  workerId?: string | null;
}

/**
 * Autogenerated input type of clientValidatePromoCode
 */
export interface clientValidatePromoCodeInput {
  clientMutationId?: string | null;
  promoCode: string;
  lineItemIds: string[];
  clientId?: string | null;
}

/**
 * Autogenerated input type of completeVisit
 */
export interface completeVisitInput {
  clientMutationId?: string | null;
  visitId: string;
  actualStartTime?: any | null;
  actualEndTime?: any | null;
  confirmations?: ConfirmationType[] | null;
  skipConfirmation?: boolean | null;
}

/**
 * Autogenerated input type of completeWorkerTask
 */
export interface completeWorkerTaskInput {
  clientMutationId?: string | null;
  workerTaskId: string;
}

/**
 * Autogenerated input type of confirmInvoiceBatch
 */
export interface confirmInvoiceBatchInput {
  clientMutationId?: string | null;
  invoicesIds: string[];
}

/**
 * Autogenerated input type of confirmInvoice
 */
export interface confirmInvoiceInput {
  clientMutationId?: string | null;
  invoiceId: string;
}

/**
 * Autogenerated input type of confirmPackageRequest
 */
export interface confirmPackageRequestInput {
  clientMutationId?: string | null;
  packageRequestId: string;
}

/**
 * Autogenerated input type of confirmPayrollBatch
 */
export interface confirmPayrollBatchInput {
  clientMutationId?: string | null;
  payrollsIds: string[];
}

/**
 * Autogenerated input type of confirmPayroll
 */
export interface confirmPayrollInput {
  clientMutationId?: string | null;
  payrollId: string;
}

/**
 * Autogenerated input type of convertBankStatementCsv
 */
export interface convertBankStatementCsvInput {
  clientMutationId?: string | null;
  fileString: string;
  bankAccount: BankAccountEnum;
}

/**
 * Autogenerated input type of createAddress
 */
export interface createAddressInput {
  clientMutationId?: string | null;
  clientId: string;
  fullAddress: string;
  unitNumber?: string | null;
  postalCode: string;
  primary: boolean;
}

/**
 * Autogenerated input type of createAdhocPackage
 */
export interface createAdhocPackageInput {
  clientMutationId?: string | null;
  accessType?: string | null;
  additionalNotes?: string | null;
  fullAddress: string;
  postalCode: string;
  unitNumber?: string | null;
  billingUnit: PackageDetailBillingUnitEnum;
  contractId: string;
  endTime?: string | null;
  packageDetailId?: string | null;
  packageDescription?: string | null;
  serviceDate: any;
  startTime?: string | null;
  unitValue?: number | null;
  creationReason: CreationReasonEnum;
  workerId?: string | null;
  workerContractId?: string | null;
  packageLineItems?: PackageLineItemInput[] | null;
  department?: PackageDepartmentEnum | null;
  billingType?: BillingTypeEnum | null;
  skipOverdueInvoiceCheck?: boolean | null;
  creationSource?: CreationSourceInput | null;
  promoCode?: string | null;
}

/**
 * Autogenerated input type of createClient
 */
export interface createClientInput {
  clientMutationId?: string | null;
  name: string;
  accountType: ClientAccountTypeEnum;
  primaryAddress: AddressInput;
  otherAddresses?: AddressInput[] | null;
  primaryContact: ContactInput;
  otherContacts?: ContactInput[] | null;
}

/**
 * Autogenerated input type of createClientIssueRequest
 */
export interface createClientIssueRequestInput {
  clientMutationId?: string | null;
  visitId: string;
  issueId: string;
  comment: string;
}

/**
 * Autogenerated input type of createClientNote
 */
export interface createClientNoteInput {
  clientMutationId?: string | null;
  clientId: string;
  body: string;
}

/**
 * Autogenerated input type of createClientVoucher
 */
export interface createClientVoucherInput {
  clientMutationId?: string | null;
  masterVoucherId: string;
  clientId: string;
  discountValue: number;
}

/**
 * Autogenerated input type of createContact
 */
export interface createContactInput {
  clientMutationId?: string | null;
  clientId: string;
  firstName: string;
  lastName: string;
  email: string[];
  phoneNumber: string[];
  primary: boolean;
}

/**
 * Autogenerated input type of createContract
 */
export interface createContractInput {
  clientMutationId?: string | null;
  clientId: string;
  billingAddressId: string;
  billingContactId: string;
  invoicing: InvoicingInput;
}

/**
 * Autogenerated input type of createFinalPayroll
 */
export interface createFinalPayrollInput {
  clientMutationId?: string | null;
  workerId: string;
  billingDate: any;
}

/**
 * Autogenerated input type of createLeaveApplication
 */
export interface createLeaveApplicationInput {
  clientMutationId?: string | null;
  workerId: string;
  leaveType: LeaveTypeEnum;
  startDate: any;
  endDate: any;
  hoursApplied: number;
  reason?: string | null;
}

/**
 * Autogenerated input type of createMasterPromoCode
 */
export interface createMasterPromoCodeInput {
  clientMutationId?: string | null;
  promoCode: string;
  discountType: DiscountTypeEnum;
  discountValue: number;
  firstTransactionOnly: boolean;
  validUntil?: any | null;
  departments?: PackageDepartmentEnum[] | null;
}

/**
 * Autogenerated input type of createPackage
 */
export interface createPackageInput {
  clientMutationId?: string | null;
  accessType?: string | null;
  additionalNotes?: string | null;
  fullAddress: string;
  postalCode: string;
  unitNumber?: string | null;
  billingUnit?: PackageDetailBillingUnitEnum | null;
  contractId: string;
  endDate?: any | null;
  packageDetailId?: string | null;
  packageDescription?: string | null;
  startDate: any;
  unitValue?: number | null;
  tasks?: TaskInput[] | null;
  startTime?: string | null;
  endTime?: string | null;
  creationReason: CreationReasonEnum;
  workerId?: string | null;
  workerContractId?: string | null;
  packageLineItems?: PackageLineItemInput[] | null;
  firstVisitLineItems?: PackageLineItemInput[] | null;
  department?: PackageDepartmentEnum | null;
  billingType?: BillingTypeEnum | null;
  skipOverdueInvoiceCheck?: boolean | null;
  creationSource?: CreationSourceInput | null;
  promoCode?: string | null;
}

/**
 * Autogenerated input type of createPackageRequest
 */
export interface createPackageRequestInput {
  clientMutationId?: string | null;
  clientId: string;
  contractId?: string | null;
  addressId: string;
  packageDetailId: string;
  startDate: any;
  endDate?: any | null;
  additionalNotes?: string | null;
  tasks: TaskInput[];
}

/**
 * Autogenerated input type of createPayrollAdjustment
 */
export interface createPayrollAdjustmentInput {
  clientMutationId?: string | null;
  workerId: string;
  amount: number;
  comment: string;
  startDate: any;
  endDate?: any | null;
  cpfContributable: boolean;
}

/**
 * Autogenerated input type of createPromotion
 */
export interface createPromotionInput {
  clientMutationId?: string | null;
  title: string;
  url: string;
  status: PromotionStatusEnum;
  startDate: any;
  files?: any[] | null;
  endDate?: any | null;
  buttonLabel?: string | null;
}

/**
 * Autogenerated input type of createRentalPackage
 */
export interface createRentalPackageInput {
  clientMutationId?: string | null;
  accessType?: string | null;
  additionalNotes?: string | null;
  fullAddress: string;
  postalCode: string;
  unitNumber?: string | null;
  billingUnit?: PackageDetailBillingUnitEnum | null;
  contractId: string;
  endDate?: any | null;
  packageDetailId?: string | null;
  packageDescription?: string | null;
  startDate: any;
  unitValue?: number | null;
  creationReason: CreationReasonEnum;
  packageLineItems?: PackageLineItemInput[] | null;
  department?: PackageDepartmentEnum | null;
  billingType?: BillingTypeEnum | null;
}

/**
 * Autogenerated input type of createVisitBillingAdjustment
 */
export interface createVisitBillingAdjustmentInput {
  clientMutationId?: string | null;
  visitId: string;
  amount: number;
  adjustmentType: VisitBillingAdjustmentTypes;
  reason: string;
}

/**
 * Autogenerated input type of createVisitPayrollAdjustment
 */
export interface createVisitPayrollAdjustmentInput {
  clientMutationId?: string | null;
  visitId: string;
  workerId: string;
  amount: number;
  adjustmentType: VisitPayrollAdjustmentTypes;
  reason: string;
}

/**
 * Autogenerated input type of createWorkerContract
 */
export interface createWorkerContractInput {
  clientMutationId?: string | null;
  workerId: string;
  workerContractDetailId: string;
  contractType: ContractTypeEnum;
  startDate: any;
  contractName: string;
  payrollUnit?: ContractPayrollUnitEnum | null;
  unitValue?: number | null;
  annualLeave?: number | null;
  sickLeave?: number | null;
  hospitalLeave?: number | null;
  signedContractUrls?: string[] | null;
  files?: any[] | null;
  workerContractDays: WorkerContractDayInput[];
  workerContractPayrollPolicies?: PayrollPolicyInput[] | null;
  workerSkillIds: string[];
  jobId?: string | null;
}

/**
 * Autogenerated input type of createWorkerContractPayrollPolicy
 */
export interface createWorkerContractPayrollPolicyInput {
  clientMutationId?: string | null;
  workerContractId: string;
  workerContractPayrollPolicies?: PayrollPolicyInput[] | null;
}

/**
 * Autogenerated input type of createWorker
 */
export interface createWorkerInput {
  clientMutationId?: string | null;
  firstName: string;
  lastName: string;
  nric: string;
  nameOnId?: string | null;
  dob: any;
  employmentStartDate: any;
  nationality: NationalityEnum;
  contactNumber: string;
  gender: SexEnum;
  languageSpoken: LanguageSpokenEnum[];
  postalCode: string;
  address: string;
  addressUnitNumber?: string | null;
  bankName: BankNameEnum;
  accountNumber: string;
  techSavy?: boolean | null;
  managerId?: string | null;
  workerType: WorkerWorkerTypeEnum;
  modeOfTransport: WorkerModeOfTransportEnum;
  clientBookingOption?: boolean | null;
  department: WorkerDepartmentEnum;
  departmentId?: string | null;
  emergencyContactName?: string | null;
  emergencyContactNumber?: string | null;
  email?: string | null;
  avatar?: any | null;
  servicePostalCode: string;
  payrollAcknowledgementRequired: boolean;
  addressCheckRequired: boolean;
}

/**
 * Autogenerated input type of createWorkerNote
 */
export interface createWorkerNoteInput {
  clientMutationId?: string | null;
  workerId: string;
  body: string;
}

/**
 * Autogenerated input type of createWorkerTask
 */
export interface createWorkerTaskInput {
  clientMutationId?: string | null;
  workerId: string;
  workerContractId?: string | null;
  fullAddress: string;
  postalCode: string;
  unitNumber?: string | null;
  paymentType: TaskPaymentTypesEnum;
  paymentAmount?: number | null;
  taskDate: any;
  startTime: string;
  endTime: string;
  accessInstruction?: string | null;
  additionalNotes?: string | null;
  allowOverlap?: boolean | null;
  phoneNumber?: string | null;
}

/**
 * Autogenerated input type of createWorkerTip
 */
export interface createWorkerTipInput {
  clientMutationId?: string | null;
  title: string;
  message: string;
  department: WorkerDepartmentEnum;
  departmentId?: string | null;
  nationality: NationalityEnum;
}

/**
 * Autogenerated input type of deleteAddress
 */
export interface deleteAddressInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteClientDocuments
 */
export interface deleteClientDocumentsInput {
  clientMutationId?: string | null;
  clientId: string;
  ids: string[];
}

/**
 * Autogenerated input type of deleteClient
 */
export interface deleteClientInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteClientNote
 */
export interface deleteClientNoteInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteClientVoucher
 */
export interface deleteClientVoucherInput {
  clientMutationId?: string | null;
  voucherId: string;
}

/**
 * Autogenerated input type of deleteContact
 */
export interface deleteContactInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteContract
 */
export interface deleteContractInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteInvoice
 */
export interface deleteInvoiceInput {
  clientMutationId?: string | null;
  invoiceId: string;
}

/**
 * Autogenerated input type of deleteLeaveApplication
 */
export interface deleteLeaveApplicationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deletePackage
 */
export interface deletePackageInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deletePackageRequest
 */
export interface deletePackageRequestInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deletePayrollAdjustment
 */
export interface deletePayrollAdjustmentInput {
  clientMutationId?: string | null;
  payrollAdjustmentId: string;
}

/**
 * Autogenerated input type of deletePortfolios
 */
export interface deletePortfoliosInput {
  clientMutationId?: string | null;
  workerContractId: string;
  ids: string[];
}

/**
 * Autogenerated input type of deletePromotion
 */
export interface deletePromotionInput {
  clientMutationId?: string | null;
  promotionId: string;
}

/**
 * Autogenerated input type of deleteProofOfAgreements
 */
export interface deleteProofOfAgreementsInput {
  clientMutationId?: string | null;
  workerContractId: string;
  ids: string[];
}

/**
 * Autogenerated input type of deleteStaffReviewAttachment
 */
export interface deleteStaffReviewAttachmentInput {
  clientMutationId?: string | null;
  visitId: string;
  idFiles: string[];
}

/**
 * Autogenerated input type of deleteTransaction
 */
export interface deleteTransactionInput {
  clientMutationId?: string | null;
  transactionId: string;
}

/**
 * Autogenerated input type of deleteVisitBillingAdjustment
 */
export interface deleteVisitBillingAdjustmentInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteVisitLineItem
 */
export interface deleteVisitLineItemInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteVisitPayrollAdjustment
 */
export interface deleteVisitPayrollAdjustmentInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteWorkerContract
 */
export interface deleteWorkerContractInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteWorkerDocumentFiles
 */
export interface deleteWorkerDocumentFilesInput {
  clientMutationId?: string | null;
  workerDocumentId: string;
  idFiles: string[];
}

/**
 * Autogenerated input type of deleteWorkerDocument
 */
export interface deleteWorkerDocumentInput {
  clientMutationId?: string | null;
  workerDocumentId: string;
}

/**
 * Autogenerated input type of deleteWorkerNote
 */
export interface deleteWorkerNoteInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of deleteWorkerTask
 */
export interface deleteWorkerTaskInput {
  clientMutationId?: string | null;
  workerTaskId: string;
}

/**
 * Autogenerated input type of downloadSalesAgentCommissionCsv
 */
export interface downloadSalesAgentCommissionCsvInput {
  clientMutationId?: string | null;
  fromDate: any;
  toDate: any;
  agentId?: string[] | null;
  salesAgentShortcode?: string[] | null;
  department?: PackageDepartmentEnum | null;
  repeatEvery?: PackageRecurrenceEnum | null;
  repeatEveryTimes?: number | null;
  accountType?: ClientAccountTypeEnum | null;
  packageStatus?: PackageStatusEnum | null;
}

/**
 * Autogenerated input type of downloadVisitCsv
 */
export interface downloadVisitCsvInput {
  clientMutationId?: string | null;
  status?: VisitStatusEnum[] | null;
  manager?: string | null;
  department?: PackageDepartmentEnum | null;
  workerDepartment?: WorkerDepartmentEnum[] | null;
  search?: string | null;
  startDate: any;
  endDate: any;
}

/**
 * Autogenerated input type of generateInvoiceCsv
 */
export interface generateInvoiceCsvInput {
  clientMutationId?: string | null;
  invoiceIds: string[];
}

/**
 * Autogenerated input type of generateInvoicePdf
 */
export interface generateInvoicePdfInput {
  clientMutationId?: string | null;
  token: string;
}

/**
 * Autogenerated input type of generateOutstandingPayrollsCsv
 */
export interface generateOutstandingPayrollsCsvInput {
  clientMutationId?: string | null;
  payrollIds: string[];
}

/**
 * Autogenerated input type of generatePayrollCsv
 */
export interface generatePayrollCsvInput {
  clientMutationId?: string | null;
  token: string;
}

/**
 * Autogenerated input type of inviteUser
 */
export interface inviteUserInput {
  clientMutationId?: string | null;
  email: string;
  name: string;
}

/**
 * Autogenerated input type of rateVisit
 */
export interface rateVisitInput {
  clientMutationId?: string | null;
  visitId: string;
  rating: number;
  ratingComment?: string | null;
  files?: any[] | null;
}

/**
 * Autogenerated input type of regenerateInvoice
 */
export interface regenerateInvoiceInput {
  clientMutationId?: string | null;
  invoiceId: string;
}

/**
 * Autogenerated input type of regeneratePayroll
 */
export interface regeneratePayrollInput {
  clientMutationId?: string | null;
  payrollId: string;
}

/**
 * Autogenerated input type of rejectLeaveApplication
 */
export interface rejectLeaveApplicationInput {
  clientMutationId?: string | null;
  leaveApplicationId: string;
}

/**
 * Autogenerated input type of rejectPackageRequest
 */
export interface rejectPackageRequestInput {
  clientMutationId?: string | null;
  packageRequestId: string;
}

/**
 * Autogenerated input type of removePaymentProof
 */
export interface removePaymentProofInput {
  clientMutationId?: string | null;
  invoiceId: string;
}

/**
 * Autogenerated input type of removeVisitNoteAttachments
 */
export interface removeVisitNoteAttachmentsInput {
  clientMutationId?: string | null;
  visitId: string;
  noteAttachmentIds: string[];
}

/**
 * Autogenerated input type of resetPasswordFromEmail
 */
export interface resetPasswordFromEmailInput {
  clientMutationId?: string | null;
  email: string;
  pinNumber: string;
  newPassword: string;
}

/**
 * Autogenerated input type of restoreLeaveApplication
 */
export interface restoreLeaveApplicationInput {
  clientMutationId?: string | null;
  leaveApplicationId: string;
  reassignWorker: boolean;
  workerContractId?: string | null;
}

/**
 * Autogenerated input type of restoreVisit
 */
export interface restoreVisitInput {
  clientMutationId?: string | null;
  visitId: string;
}

/**
 * Autogenerated input type of restoreWorkerTask
 */
export interface restoreWorkerTaskInput {
  clientMutationId?: string | null;
  workerTaskId: string;
}

/**
 * Autogenerated input type of sendInvoiceEmail
 */
export interface sendInvoiceEmailInput {
  clientMutationId?: string | null;
  invoiceId: string;
}

/**
 * Autogenerated input type of sendResetPassword
 */
export interface sendResetPasswordInput {
  clientMutationId?: string | null;
  email: string;
  type?: ResetPasswordEnum | null;
}

/**
 * Autogenerated input type of signInClassic
 */
export interface signInClassicInput {
  clientMutationId?: string | null;
  email: string;
  password: string;
  type?: UserEnum | null;
  installation?: InstallationInput | null;
}

/**
 * Autogenerated input type of signUp
 */
export interface signUpInput {
  clientMutationId?: string | null;
  email: string;
  phoneNumber: string;
  password?: string | null;
  provider?: SignInProviderEnum | null;
  token?: string | null;
  refreshToken?: string | null;
  firstName: string;
  lastName: string;
  type?: UserEnum | null;
  accountType?: ClientAccountTypeEnum | null;
  primaryAddress?: AddressInput | null;
  primaryContact?: ContactInput | null;
  installation?: InstallationInput | null;
  utm?: UtmInput | null;
  adTrackingKey?: string | null;
  inviteCode?: string | null;
  creationSource?: CreationSourceInput | null;
}

/**
 * Autogenerated input type of terminateJob
 */
export interface terminateJobInput {
  clientMutationId?: string | null;
  jobId: string;
  endDate: any;
}

/**
 * Autogenerated input type of terminatePackage
 */
export interface terminatePackageInput {
  clientMutationId?: string | null;
  packageId: string;
  endDate: any;
  terminationCategory: PackageTerminationCategoryEnum;
  terminationReason: PackageTerminationReasonEnum;
  terminationComment?: string | null;
}

/**
 * Autogenerated input type of terminateWorkerContract
 */
export interface terminateWorkerContractInput {
  clientMutationId?: string | null;
  id: string;
  endDate: any;
}

/**
 * Autogenerated input type of terminateWorker
 */
export interface terminateWorkerInput {
  clientMutationId?: string | null;
  workerId: string;
  endDate: any;
}

/**
 * Autogenerated input type of updateAddress
 */
export interface updateAddressInput {
  clientMutationId?: string | null;
  id: string;
  fullAddress?: string | null;
  postalCode?: string | null;
  primary?: boolean | null;
  unitNumber?: string | null;
}

/**
 * Autogenerated input type of updateClient
 */
export interface updateClientInput {
  clientMutationId?: string | null;
  id: string;
  name?: string | null;
  accountType?: ClientAccountTypeEnum | null;
  accountEmail?: string | null;
  accountPhoneNumber?: string | null;
  status?: ClientStatusEnum | null;
}

/**
 * Autogenerated input type of updateClientNote
 */
export interface updateClientNoteInput {
  clientMutationId?: string | null;
  id: string;
  body?: string | null;
}

/**
 * Autogenerated input type of updateClientPassword
 */
export interface updateClientPasswordInput {
  clientMutationId?: string | null;
  clientId: string;
  newPassword: string;
}

/**
 * Autogenerated input type of updateClientPreferences
 */
export interface updateClientPreferencesInput {
  clientMutationId?: string | null;
  preferences: any;
  clientId: string;
}

/**
 * Autogenerated input type of updateClientVoucher
 */
export interface updateClientVoucherInput {
  clientMutationId?: string | null;
  voucherId: string;
  expirationDate: any;
  discountValue: number;
}

/**
 * Autogenerated input type of updateContact
 */
export interface updateContactInput {
  clientMutationId?: string | null;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string[] | null;
  phoneNumber?: string[] | null;
  primary?: boolean | null;
}

/**
 * Autogenerated input type of updateContract
 */
export interface updateContractInput {
  clientMutationId?: string | null;
  id: string;
  billingAddressId?: string | null;
  billingContactId?: string | null;
  billingTerms?: ContractBillingTermsEnum | null;
  invoicing?: InvoicingInput | null;
  status?: ContractStatusEnum | null;
}

/**
 * Autogenerated input type of updateLeaveApplication
 */
export interface updateLeaveApplicationInput {
  clientMutationId?: string | null;
  id: string;
  startDate: any;
  endDate: any;
  hoursApplied: number;
  reason?: string | null;
}

/**
 * Autogenerated input type of updateManager
 */
export interface updateManagerInput {
  clientMutationId?: string | null;
  managerId: string;
  userUpdate?: UserUpdateInput | null;
  role?: ManagerRoleEnum | null;
  opsExecCode?: string | null;
  hubspotId?: string | null;
  lastWorkingDay?: any | null;
  status?: ManagerStatusEnum | null;
}

/**
 * Autogenerated input type of updateMasterPromoCode
 */
export interface updateMasterPromoCodeInput {
  clientMutationId?: string | null;
  id: string;
  discountType?: DiscountTypeEnum | null;
  discountValue?: number | null;
  validUntil?: any | null;
  firstTransactionOnly?: boolean | null;
  departments?: PackageDepartmentEnum[] | null;
}

/**
 * Autogenerated input type of updatePackage
 */
export interface updatePackageInput {
  clientMutationId?: string | null;
  id: string;
  packageDescription?: string | null;
  unitValue?: number | null;
  fullAddress?: string | null;
  postalCode?: string | null;
  unitNumber?: string | null;
  accessType?: string | null;
  additionalNotes?: string | null;
}

/**
 * Autogenerated input type of updatePackageJobs
 */
export interface updatePackageJobsInput {
  clientMutationId?: string | null;
  packageId: string;
  jobs: JobInput[];
}

/**
 * Autogenerated input type of updatePayrollAdjustment
 */
export interface updatePayrollAdjustmentInput {
  clientMutationId?: string | null;
  payrollAdjustmentId: string;
  payrollId?: string | null;
  amount: number;
  adjustmentType?: string | null;
  comment?: string | null;
  startDate: any;
  endDate?: any | null;
  cpfContributable: boolean;
}

/**
 * Autogenerated input type of updatePromotion
 */
export interface updatePromotionInput {
  clientMutationId?: string | null;
  promotionId: string;
  title?: string | null;
  files?: any[] | null;
  url?: string | null;
  status?: PromotionStatusEnum | null;
  startDate?: any | null;
  endDate?: any | null;
  buttonLabel?: string | null;
}

/**
 * Autogenerated input type of updateVisitBillingAdjustment
 */
export interface updateVisitBillingAdjustmentInput {
  clientMutationId?: string | null;
  id: string;
  amount?: number | null;
  reason?: string | null;
  adjustmentType: VisitBillingAdjustmentTypes;
}

/**
 * Autogenerated input type of updateVisit
 */
export interface updateVisitInput {
  clientMutationId?: string | null;
  id: string;
  serviceDate?: any | null;
  startTime?: string | null;
  endTime?: string | null;
  workingHours?: TaskWorkingHoursInput[] | null;
  extendExpirationDate?: boolean | null;
  statusComment?: string | null;
  accessType?: string | null;
  packageNotes?: string | null;
}

/**
 * Autogenerated input type of updateVisitLineItem
 */
export interface updateVisitLineItemInput {
  clientMutationId?: string | null;
  id: string;
  packageDescription: string;
  unitValue: number;
  units: number;
}

/**
 * Autogenerated input type of updateVisitLineItems
 */
export interface updateVisitLineItemsInput {
  clientMutationId?: string | null;
  visitId: string;
  lineItems: VisitLineItemInput[];
  skipOverlapCheck?: boolean | null;
}

/**
 * Autogenerated input type of updateVisitPayrollAdjustment
 */
export interface updateVisitPayrollAdjustmentInput {
  clientMutationId?: string | null;
  id: string;
  amount?: number | null;
  reason?: string | null;
  adjustmentType: VisitPayrollAdjustmentTypes;
}

/**
 * Autogenerated input type of updateWorkerContractDay
 */
export interface updateWorkerContractDayInput {
  clientMutationId?: string | null;
  workerContractId: string;
  workerContractDays: WorkerContractDayInput[];
}

/**
 * Autogenerated input type of updateWorkerContract
 */
export interface updateWorkerContractInput {
  clientMutationId?: string | null;
  id: string;
  workerContractDetailId?: string | null;
  contractType?: ContractTypeEnum | null;
  contractName?: string | null;
  payrollUnit?: ContractPayrollUnitEnum | null;
  unitValue?: number | null;
  signedContractUrls?: string[] | null;
  annualLeave?: number | null;
  sickLeave?: number | null;
  hospitalLeave?: number | null;
  startDate?: any | null;
}

/**
 * Autogenerated input type of updateWorkerContractSkills
 */
export interface updateWorkerContractSkillsInput {
  clientMutationId?: string | null;
  workerContractId: string;
  workerSkillIds: string[];
}

/**
 * Autogenerated input type of updateWorkerDocument
 */
export interface updateWorkerDocumentInput {
  clientMutationId?: string | null;
  workerDocumentId: string;
  docType?: WorkerDocumentTypeEnum | null;
  docNumber?: string | null;
  issuanceDate?: any | null;
  expirationDate?: any | null;
  additionalDescription?: string | null;
  files?: any[] | null;
}

/**
 * Autogenerated input type of updateWorker
 */
export interface updateWorkerInput {
  clientMutationId?: string | null;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  nric?: string | null;
  nameOnId?: string | null;
  dob?: any | null;
  nationality?: NationalityEnum | null;
  contactNumber?: string | null;
  gender?: SexEnum | null;
  languageSpoken?: LanguageSpokenEnum[] | null;
  postalCode?: string | null;
  address?: string | null;
  addressUnitNumber?: string | null;
  bankName?: BankNameEnum | null;
  accountNumber?: string | null;
  techSavy?: boolean | null;
  managerId?: string | null;
  workerType?: WorkerWorkerTypeEnum | null;
  status?: WorkerStatusEnum | null;
  emergencyContactName?: string | null;
  emergencyContactNumber?: string | null;
  email?: string | null;
  avatar?: any | null;
  employmentStartDate?: any | null;
  servicePostalCode?: string | null;
  modeOfTransport?: WorkerModeOfTransportEnum | null;
  clientBookingOption?: boolean | null;
  department?: WorkerDepartmentEnum | null;
  departmentId?: string | null;
  payrollAcknowledgementRequired?: boolean | null;
  addressCheckRequired?: boolean | null;
}

/**
 * Autogenerated input type of updateWorkerNote
 */
export interface updateWorkerNoteInput {
  clientMutationId?: string | null;
  id: string;
  body?: string | null;
}

/**
 * Autogenerated input type of updateWorkerPreferences
 */
export interface updateWorkerPreferencesInput {
  clientMutationId?: string | null;
  workerId: string;
  preferences: any;
}

/**
 * Autogenerated input type of updateWorkerTask
 */
export interface updateWorkerTaskInput {
  clientMutationId?: string | null;
  id: string;
  fullAddress: string;
  postalCode: string;
  unitNumber?: string | null;
  paymentType: TaskPaymentTypesEnum;
  paymentAmount?: number | null;
  taskDate: any;
  startTime: string;
  endTime: string;
  accessInstruction?: string | null;
  additionalNotes?: string | null;
  allowOverlap?: boolean | null;
  phoneNumber?: string | null;
}

/**
 * Autogenerated input type of updateWorkerTip
 */
export interface updateWorkerTipInput {
  clientMutationId?: string | null;
  id: string;
  title?: string | null;
  message?: string | null;
  department?: WorkerDepartmentEnum | null;
  departmentId?: string | null;
  nationality?: NationalityEnum | null;
}

/**
 * Autogenerated input type of uploadOutstandingPayrollsCsv
 */
export interface uploadOutstandingPayrollsCsvInput {
  clientMutationId?: string | null;
  fileString: string;
}

/**
 * Autogenerated input type of withdrawCreditAuthorized
 */
export interface withdrawCreditAuthorizedInput {
  clientMutationId?: string | null;
  transactionId: string;
  payoutDate: any;
}

/**
 * Autogenerated input type of withdrawCredit
 */
export interface withdrawCreditInput {
  clientMutationId?: string | null;
  creditAccountId: string;
  amount: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
